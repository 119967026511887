@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.approvedComponent {
  width: 100%;
  @include font(2);

  // .contentWrapper {
  //   width: 100%;
  //   margin-top: 20px;
  //   margin-bottom: 20px;
  //   padding-bottom: 40px;

  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 8px;
    margin-left: 10px;
  }

  .titleRows {
    padding-top: 30px;
    padding-left: 18px;
    padding-right: 15px;

    .innerComponent {
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
      background-color: $white;
      box-sizing: border-box;
      position: relative;

      .component {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    img {
      margin-right: 10px;
    }

    h4 {
      padding-top: 5px;
    }
    .link {
      cursor: pointer;
    }
  }

  .topRow {
    padding: 30px;
  }

  .topAndSideOnlyRow {
    padding: 30px 30px 0px 30px;
  }
  .sideRow {
    padding: 0px 30px;
    padding-bottom: 30px;
  }

  .margin5 {
    margin: 0px 5px;
  }
  .btnSaveChanges {
    width: 100px !important;
    height: 36px !important;
  }

  .addbutton {
    float: right;
  }

  .btnCancel {
    width: 100px;
    margin: 0px 5px;
    height: 36px !important;
  }
  .error {
    @include font(2);
    color: $error !important;
  }
  .ddlError > div {
    border: 1px solid $error;
  }

  .innerTable {
    padding: 1.25rem;
    vertical-align: top;
    border-top: none;
    font-size: 15px;
    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 0px !important;

    thead th {
      padding-bottom: 5px;
      background-color: $border;
      border: none !important;
      color: $textColor;
      height: 40px;
      vertical-align: middle !important;
      padding-top: 5px !important;
    }

    tbody tr {
      border: none;
    }

    tr td:first-child {
      font-weight: normal;
    }

    tbody tr td {
      border-top: 1px solid $border;
    }

    .btnCol {
      text-align: right;
    }
    .viewButton {
      margin-top: 10px;
      max-width: 100px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .removeButton {
      margin-top: 10px;
      float: right;
      max-width: 100px;
      margin-right: 20px;
    }
  }
  .newRow {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
}
