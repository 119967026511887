@import '../../styles/variables';
@import '../../styles/global';

.commandPanel {
  width: 100%;
  padding: 20px 30px;
  border-radius: 4px;
  background-color: $white;
  box-sizing: border-box;
  height: 100%;

  h4 {
    margin-bottom: 10px;
  }

  p {
    @include font(1);
  }

  .startStopButton {
    border-bottom: solid 1px $greyBackground;
    padding-bottom: 30px;
    padding-top: 20px;
  }

  .buttonHolder {
    padding-top: 30px;
  }
}

@media (max-width: 768px) {
  .commandPanel {
    h4 {
      text-align: left !important;
      margin-left: -5px !important;
    }
  }
  .startStopButton {
    padding-bottom: 20px !important;
  }

  .buttonHolder {
    padding-top: 20px !important;
  }

  .commandbutton {
    padding: 0 !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .commandbutton {
    padding: 0 !important;
  }
}
