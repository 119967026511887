@import '../../styles/variables';
@import '../../styles/global';

.chart {
  width: 100%;
  height: 210px;
  min-width: 0px;
}

:global {
  .recharts-cartesian-axis-tick text {
    @include font(1);
    fill: $textColor;
  }

  .recharts-label {
    @include font(2);
    fill: $textColor;
  }
}
