@import '../../styles/variables';
@import '../../styles/global';

.borderRed {
  border-left: solid 6px $danger !important;
}
p {
  margin-bottom: 1rem !important;
}

.eventAction {
  width: 100%;

  .eventActionContainer {
    border-radius: 6px;

    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    min-height: 600px;

    .rowContents {
      padding-left: 50px;
      padding-right: 20px;
    }

    .colContents {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .alarmMessage {
      @include font(2, 'medium');
      margin-left: 10px !important;
    }

    .eventActionHeader {
      margin-top: 40px;
      margin-bottom: 20px;
      @include font(5);
      color: $darkBlue !important;
    }

    .rHSSection {
      padding-left: 25px;
      border-left: solid 1px $border !important;
    }

    .lHSSection {
      margin-right: 15px;
    }

    .greenTick {
      margin-left: 10px;
    }

    .eventActionContents {
      p {
        @include font(2);
        color: $textColor;
      }
    }

    .eventActionActivityHistory {
      margin-top: 50px;
      border-top: solid 1px $border;
    }

    .eventActionActivityHistNoActivity {
      @include font(2);
      color: $textColor;
      margin-top: 10px;
      font-style: italic;
    }
  }
  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-left: 10px;
  }

  .taskContainer {
    margin-top: 50px;

    .colAddButton {
      text-align: right;
    }

    .subHeading {
      margin-bottom: 20px;
    }

    h4 {
      display: inline;
    }

    .ddlTaskStatusSearch {
      max-width: 180px;
    }
  }

  .trendAnalysis {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    box-sizing: border-box;
    position: relative;
    min-height: 100px;
  }

  .telemetry {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    box-sizing: border-box;
    position: relative;
    min-height: 100px;

    .formControl {
      margin-bottom: 0px;
    }

    .telemetryContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .subHeading {
        padding: 10px 0px;
      }

      .divWarning {
        margin-bottom: 20px;
        display: flex;
        align-content: center;
        align-items: center;
      }
      .downloadInfo {
        padding-top: 10px;
        padding-left: 5px;
      }
      .downloadHistoryInfo {
        padding-top: 10px;
        padding-left: 0px;
      }
      .breakhr {
        margin-top: 40px;
      }

      .icon {
        cursor: pointer;
        margin-right: 10px;
      }

      .telemetryLinks {
        padding-top: 5px;
        padding-bottom: 5px;

        .downloadIcon {
          width: 25px;
        }
        .telemetryLinkItems {
          padding-top: 15px;
        }
      }
    }
  }

  .titleRows {
    img {
      padding-right: 5px;
      margin-bottom: 5px;
    }
  }
  .collapsableSection {
    margin-left: 15px;
    margin-top: 20px;
  }
}
