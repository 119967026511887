@import '../../styles/variables';
@import '../../styles/global';

.calibration {
  width: 100%;
  @include font(2);

  .btnSaveChanges {
    width: 230px !important;
    background-color: transparent !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    height: 50px !important;
  }

  .btnCancel {
    background-color: transparent !important;
    width: 230px;
    margin: 0px 5px;
    height: 50px !important;
  }

  .changeCalibrationAlt {
    width: 204px !important;
    float: left !important;
    margin-right: 20px;
  }

  .deleteButton {
    width: 230px !important;
    background-color: transparent !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    height: 50px !important;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .calibrationHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .changeCalibration {
      width: 204px !important;
      float: right !important;
    }

    .calibrationContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .wsaDropdown {
        width: 100%;
        padding-bottom: 10px;

        div[class*='control'] {
          height: 48px;
        }

        div[class*='menu-list'] {
          div[class*='option'] {
            border-bottom: solid 1px $border;
          }
        }

        .textArea {
          height: auto !important;
        }

        span[class*='indicatorSeparator'] {
          background-color: $white;
        }
      }
      .breakhr {
        margin: 40px 0px;
      }
      .breakrow {
        margin-top: 15px !important;
        .paddingTop {
          padding-top: 15px;
        }
      }
      .subheading {
        padding-top: 10px;
      }

      .addbutton {
        width: 194px !important;
        float: right;
      }

      @media (max-width: 768px) {
        .addbutton {
          width: 100% !important;
        }
        .changeButton {
          float: left !important;
          width: 50px !important;
          margin-right: 0px !important;
          font-size: 10px !important;
          padding-left: 4px !important;
          padding-right: 4px !important;
        }
      }
    }

    .calibrationResults {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px 0px;
      box-sizing: border-box;
      position: relative;

      .ddlError > div {
        border: 1px solid $error;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
      .standardRow {
        margin-top: 15px !important;
        padding-right: 40px;
        padding-left: 40px;
      }

      .subheading {
        padding-top: 10px;
      }

      .addbutton {
        width: 194px !important;
        float: right;
      }

      .innerTable {
        vertical-align: top;
        border-top: none;
        font-size: 15px;
        width: 100%;
        margin-top: 20px;
        padding-bottom: 0px;
        margin-bottom: 0px !important;

        thead th {
          padding-bottom: 5px;
          font-weight: 500;
          background-color: $border;
          border: none !important;
          color: $textColor;
          height: 40px;
          vertical-align: middle !important;
          padding-top: 5px !important;
        }
        .textResultInput {
          width: 125px;
          margin-bottom: 0px;
        }
        .textInput {
          width: 125px;
          height: 50px !important;
          @include font(2);
          line-height: 1.5rem !important;
          color: $textColor !important;
          border: 1px solid $border !important;
          margin-bottom: 0px;
          border-radius: 4px;
          padding: 2px 8px;
        }
        tbody tr {
          border: none;
        }

        tr td:first-child {
          font-weight: normal;
        }

        tbody tr td {
          border-top: 1px solid $border;
        }

        .emptyCol {
          width: 40%;
        }

        .cellClass {
          vertical-align: middle;
        }

        .errorCalCell {
          height: 50%;
        }

        .removeButton {
          float: right;
          width: 100px;
          margin-right: 20px;
        }

        .icon {
          cursor: pointer;
        }
      }

      .newRow {
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
      }
    }

    .calibrationConstants {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px 0px;
      box-sizing: border-box;
      position: relative;

      .ddlError > div {
        border: 1px solid $error;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
      .standardRow {
        margin-top: 15px !important;
        padding-right: 40px;
        padding-left: 40px;
      }

      .subheading {
        padding-top: 10px;
      }
      .padleft {
        padding-left: 10px;
      }

      .switchContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .switch {
        margin-left: 0px;
      }

      .innerTable {
        vertical-align: top;
        border-top: none;
        font-size: 15px;
        width: 100%;
        margin-top: 20px;
        padding-bottom: 0px;
        margin-bottom: 0px !important;

        thead th {
          padding-bottom: 5px;
          font-weight: 500;
          background-color: $border;
          border: none !important;
          color: $textColor;
          height: 40px;
          vertical-align: middle !important;
          padding-top: 5px !important;
        }
        .textConstInput {
          width: 100px;
          margin-bottom: 0px;
        }
        .textInput {
          width: 100%;
          height: 50px !important;
          @include font(2);
          line-height: 1.5rem !important;
          color: $textColor !important;
          border: 1px solid $border !important;
          margin-bottom: 0px;
          border-radius: 4px;
          padding: 2px 8px;
        }
        tbody tr {
          border: none;
        }

        tr td:first-child {
          font-weight: normal;
        }

        tbody tr td {
          border-top: 1px solid $border;
        }

        .emptyCol {
          width: 40%;
        }
      }

      .newRow {
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
      }
    }

    .preCalErrorRow {
      border-top: 1px solid #d3dbdf;
      margin-left: 0;
      margin-right: 0;
      padding-top: 35px;
    }

    .preCalErrorRowPadding,
    .postCalErrorRowPadding {
      padding: 5px 0px 5px 45px;
    }

    .postCalErrorRow {
      margin-left: 0;
      margin-right: 0;
    }

    .calibrationComment {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 4px;
        margin-left: 10px;
      }
    }

    .calibrationFinalize {
      width: 100%;
      border-radius: 5px;
      border-width: 1px;
      border-style: solid;
      border-color: $border;
      padding: 40px;
      box-sizing: border-box;
      position: relative;
      margin-top: 40px;

      .finalizeButton {
        width: 194px !important;
        float: left;
        margin-right: 20px;
      }

      .buttonDisable {
        background-color: $border !important;
      }

      .breakrow {
        margin-top: 15px !important;
      }
    }
  }
}
