@import '../../styles/variables';
@import '../../styles/global';

.sequenceWrapper {
  width: 100%;
  padding: 20px 30px 10px;
  border-radius: 4px;
  background-color: $white;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 0px !important;
  }

  .refreshInfo {
    display: flex;
    @include font(2);

    .timestamp {
      color: $grey !important;
      padding-right: 20px;
    }

    h4 {
      padding-right: 20px;
      @include font(3);
      font-weight: 500 !important;
      color: $darkBlue !important;
    }
  }

  .refreshData {
    font-weight: 500;
    color: $darkBlue;
    cursor: pointer;

    img {
      padding-bottom: 3px;
    }

    .label {
      padding-left: 5px;
      padding-top: 5px;
    }
  }

  .smallerSpinner {
    width: 25px !important;
    height: 25px !important;
    position: relative;
    margin: inherit;
  }

  .rowBorder {
    padding-bottom: 15px;
    border-bottom: 1px solid $greyBackground;

    .switchView {
      margin-top: 10px;

      .switchViewRow {
        float: right;
      }

      img {
        margin-right: 10px;
        cursor: pointer;
      }
      span {
        @include font(2, 'medium');
        cursor: pointer;
      }
      .active {
        color: $darkBlue !important;
      }
      .nonActive {
        color: $grey !important;
      }
      .pipeSeparator {
        border-right: solid 1px $grey;
      }
    }
  }

  .sequence {
    position: relative;
    width: calc(100% + 60px);
    left: -30px;
    margin: auto;

    .diagram {
      width: 100%;
      height: 440px;

      &.diagramOverlay {
        opacity: 0.5;
      }
    }

    .switchContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    .switch {
      margin-left: 20px;
    }

    .sensors {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      p {
        position: absolute;
        background-color: $white;
        border: 1px solid $lightBlue;
        @include font(1);
        line-height: 14px;
        padding: 2px 3px;
        text-align: center;
        transform: translate(-50%, 0);
        max-width: 60px;

        span {
          display: block;
          font-weight: bold;
        }

        .arrow {
          display: none;
          position: absolute;
          width: 0;
          border-right: 1px solid $lightBlue;
        }

        &.ct01 {
          top: 255px;
          left: 352px;

          .arrow {
            display: block;
            height: 52px;
            left: 50%;
            top: 100%;
          }
        }

        &.ft01 {
          top: 144px;
          left: 284px;
        }

        &.ft02 {
          top: 195px;
          left: 580px;
        }

        &.lt01 {
          top: 9px;
          left: 193px;
        }

        &.lt02 {
          top: 223px;
          left: 646px;
        }

        &.lsl01 {
          top: 57px;
          left: 112px;
        }

        &.pt01 {
          top: 98px;
          left: 264px;
        }

        &.pt02 {
          top: 308px;
          left: 400px;
        }

        &.pt03 {
          top: 136px;
          left: 424px;
        }

        &.tb01 {
          top: 72px;
          left: 182px;
        }

        &.tb02 {
          top: 330px;
          left: 298px;
        }

        &.tb03 {
          top: 69px;
          left: 504px;
        }

        &.uvi01 {
          top: 82px;
          left: 612px;
        }
      }
    }
  }
}

.scaling-svg-container {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
}

@media (max-width: 768px) {
  .sequenceWrapper {
    .rowBorder {
      .sequenceTitle {
        padding-left: 5px !important;
      }
      .switchView {
        .switchViewRow {
          float: left !important;
        }
      }
    }

    .switch {
      margin-left: 10px !important;
    }
  }
}

@media (max-width: 390px) {
  .rowBorder {
    .switchView {
      padding: 0px !important;

      .switchViewRow {
        span {
          font-size: 12px !important;
        }
      }
    }
  }
}
