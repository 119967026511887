@import '../../styles/variables';
@import '../../styles/global';

.task {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .taskHeader {
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .taskContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .btnSaveChanges {
        width: 230px !important;
      }

      .btnCancel {
        width: 230px;
        margin: 0px 5px;
        height: 50px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
      .textArea {
        height: auto !important;
      }
      .hours {
        float: left;
        margin-left: 8px;
        margin-top: 10px;
      }
      .dueDateTime {
        max-width: 200px;
      }
      .alarmAlert {
        height: 40px !important;

        img {
          margin-right: 8px;
        }
      }
      .removeLink {
        margin-top: 7px;
        cursor: pointer;
      }

      .removeLinkDisable {
        margin-top: 7px;
        color: $grey !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .btnSaveChanges {
    margin-bottom: 20px !important;
  }
}
