@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.eventCode {
  width: 100%;
  @include font(2);

  .circle {
    height: 27px;
    width: 34px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 4px;
    margin-left: 10px;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .eventCodeHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      button {
        @include font(3, 'bold');
        color: $lightBlue;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .eventCodeContent {
      width: 100%;
      border-radius: 5px;
      @include boxShadow();
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }
    }

    .addbutton {
      width: 230px !important;
      float: right;
    }

    .changeButton {
      float: right;
      width: 100px;
      margin-right: 20px;
    }

    .dot {
      height: 12px;
      width: 12px;
      background-color: $danger;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .dotEvents {
      background-color: $lightBlue;
    }
    .dotWarnings {
      background-color: $alert;
    }
    .dotAlarms {
      background-color: $danger;
    }
    .dotVersions {
      background-color: $success;
    }

    @media (max-width: 575.99px) {
      .addbutton {
        width: 100% !important;
      }
    }

    .subHeading {
      font-size: 20px;
      color: #085488;
    }
  }
}
