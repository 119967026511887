@import '../../styles/variables';
@import '../../styles/global';

.statusActive {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: $danger;
  display: inline-flex;
  align-self: center;
  align-content: center;
  margin-right: 10px;
}

.statusComplete {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: $success;
  display: inline-flex;
  margin-right: 10px;
}

.checklistDetail {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .checklistDetailHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .btnSaveChanges {
      width: 230px;
      margin: 0px 5px 0px 0px !important;
    }

    .btnCancel {
      width: 230px;
      height: 50px !important;
    }

    .colActionButton {
      float: right;
      margin-right: 0px;
      align-items: center;
    }

    .completeSection {
      margin-bottom: 5px;
    }

    .greenTick {
      margin-left: 10px;
      margin-right: 5px;
    }

    .checklistCompleteMsg {
      color: $success;
      margin-right: 10px;
      font-weight: 500;
    }

    .taskContainer {
      margin-top: 50px;

      .colAddButton {
        text-align: right;
      }

      .innerTable {
        td,
        th {
          padding-left: 20px;
        }
      }

      .ddlTaskDetail {
        max-width: 350px !important;
      }

      .taskRow {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .activeTaskRow {
        border-left: solid 6px $danger !important;
      }

      .emptyRow {
        text-align: center;
        border-left: 0px !important;
      }

      .taskRowButton {
        margin-right: 15px;
        margin-bottom: 15px;
        float: right;
      }

      .taskButton {
        margin-right: 15px;
        height: 36px !important;
      }

      .subHeading {
        margin-bottom: 20px;
      }

      h4 {
        display: inline;
      }

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 8px;
        margin-left: 10px;
      }
      .ddlTaskStatusSearch {
        max-width: 180px;
      }

      .sortIcon {
        height: 12.2px;
        width: 10px;
        margin-left: 8px;
      }
      .sortHeading {
        cursor: pointer;
      }

      .overDue {
        padding-left: 5px;
        font-style: italic;
        color: $grey;
      }
    }
    .checklistDetailContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 20px;

      .margin5 {
        margin: 0px 5px;
      }

      .subHeading {
        @include font(4);
        color: $darkBlue !important ;
        margin-bottom: 30px;
      }

      .txtArea {
        width: 100%;
        height: 100px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .viewRow {
        padding-top: 20px !important ;
      }
      .dueDateTime {
        max-width: 200px;
      }

      .taskCompleted {
        @include font(3, 'medium');
      }
      .progressBar {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    .btnCancel {
      width: 120px !important;
      font-size: 12px !important;
    }
    .btnSaveChanges {
      width: 120px !important;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
  .checklistDetailContent {
    .dueDateTime {
      max-width: unset;
    }
  }
}
