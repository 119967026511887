@import '../../../styles/variables';
@import '../../../styles/global';

p {
  margin-bottom: 1rem !important;
}

.activity {
  width: 100%;

  .activityHistory {
    margin-top: 30px;
    border-top: solid 1px $border;
  }

  .activityHistNoActivity {
    @include font(3);
    color: $grey !important;
    margin-top: 10px;
    font-style: italic;
  }

  .activityContainer {
    .activityButton {
      margin-right: 10px;
      margin-bottom: 20px;
    }

    .activityHeading {
      margin-top: 10px;
      @include font(4);
      color: $darkBlue !important;
    }

    .textArea {
      height: 70px !important;
    }

    .error {
      @include font(2);
      margin-top: -10px;
      color: $error !important;
    }

    .errorBorder {
      border-color: $error !important;
    }

    .activityContents {
      padding: 30px 30px 0px 30px;
      background-color: $greyBackground;
      @include font(2);
      color: $textColor;
    }

    .avatar {
      height: 30px;
      width: 30px;
      background-color: $avatarBackground;
      border-radius: 15px;
    }

    .avatarDiv {
      padding-left: 3px !important;
      padding-right: 0px !important;
      max-width: 35px !important;
    }
    // .buttons{
    //   height:40px !important;
    // }
  }
}
