@import '../../../styles/variables';
@import '../../../styles/global';

.colFile {
  background-color: $greyBackground;
  margin-top: 3px;
  padding: 8px;
}
.rowFile {
  margin-left: 5px;
}

.removeBtn {
  color: $danger;
  cursor: pointer;
  float: right;
}
