@import '../../styles/variables';
@import '../../styles/global';

.deviceHeader {
  position: relative;
  padding-bottom: 30px;

  .backLink {
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-bottom: 15px;
    display: block;
  }

  h1 {
    margin-bottom: 15px;
  }

  .details {
    @include font(2);

    img {
      margin-right: 5px;
    }
    span {
      font-weight: normal;
    }

    .localTime {
      //min-width: 260px !important;
      display: flex;
      align-items: center;
      margin-left: 30px;

      .switch,
      .switch label {
        // position: absolute;
        // top: 0;
        // right: 25px;
        margin-bottom: 0 !important;
        line-height: 0;
        padding-left: 0;
        display: flex;
        align-items: center;
      }
    }

    .installedDate {
      //min-width: 260px !important;
      margin-left: 30px;
    }

    .location {
      margin-left: 30px;
    }

    .deviceStatus {
      margin-left: 15px;
    }
  }
}

@media (max-width: 768px) {
  .switch {
    margin-bottom: 0px !important;
    margin-left: auto;
  }

  .details {
    margin: 0 !important;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .location,
  .localTime,
  .installedDate,
  .deviceStatus {
    margin-left: 0px !important;
  }
}

.hideTablet {
  @media (max-width: 1200px) {
    display: none !important;
  }
}

.hideDesktop {
  @media not all and (max-width: 1200px) {
    display: none !important;
  }
}
