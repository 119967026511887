@import '../../styles/variables';
@import '../../styles/global';

.confirmDialogWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);

  .dialogBox {
    width: 400px;
    padding: 30px;
    box-sizing: border-box;
    background-color: $white;
    border-radius: 4px;
    @include boxShadow();
    pointer-events: initial;
    position: relative;

    h3 {
      font-family: $f-regular-text;
      width: 80%;
    }

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      opacity: 1;
      border: none;
      background-color: $white;

      &:before,
      &:after {
        position: absolute;
        content: '';
        height: 20px;
        left: 9px;
        top: 0px;
        width: 2px;
        background-color: $lightBlue;
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    .buttonHolder {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      button {
        width: calc(50% - 10px);
        height: 50px;
      }
    }
  }
}
