@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .dismiss {
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    display: block !important;
  }

  .popup_inner {
    position: absolute;
    left: 0;
    bottom: 6%;
    margin: auto;
    background: white;
    width: 250px;
    padding: 15px;
    border-radius: 4px;

    span {
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-bottom: 10px;
    }

    .scrollDiv {
      margin: 5px;
      padding: 5px;
      width: 100%;
      height: 180px;
      overflow: auto;
      text-align: justify;

      .formCheck {
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          @include font(2);
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: $white;
          border-radius: 50%;
          border: solid 1px $border;
        }
        /* On mouse-over, add a grey background color */
        .container:hover {
          background-color: $greyBackground;
        }

        /* When the radio button is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: $lightBlue;
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .container .checkmark:after {
          top: 5px;
          left: 5px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $white;
        }
      }
    }
  }
}
