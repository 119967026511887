.loadingScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  //   img {
  //     // width: 100px;
  //     // height: 100px;
  //   }
}
