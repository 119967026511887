@import '../../../styles/variables';
@import '../../../styles/global';

.dateWrapper {
  .wsaInput {
    width: 100%;
    height: 50px !important;
    @include font(2);
    line-height: 1.5rem !important;
    color: $textColor !important;
    border: 1px solid $border !important;
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: white;

    input[type='text']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: block;
      width: 8px;
      color: #333;
      text-align: center;
      position: relative;
    }
    input[type='text']:hover::-webkit-inner-spin-button {
      background: #eee url('../../../images/inputArrows.png') no-repeat 50% 50%;
      width: 14px;
      height: 20px;
      padding: 4px 1px;
      position: relative;
    }
    input[type='text'] {
      border: none !important;
      height: 45px !important;
      background-color: white !important;
    }
  }

  .clearText {
    width: 20px;
    height: 20px;
    padding: 4px 1px;
    position: absolute;
    right: 20px;
    top: 7px;
    color: $darkBlue;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
}
