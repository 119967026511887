@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.wrapper {
  margin: auto;
  //align-self: center;
  //position: relative;
  width: 80%;

  .mFAuthentication {
    margin: auto;
    align-self: center;
    position: relative;
    width: 80%;

    h1 {
      margin-bottom: 30px !important;
    }

    .mFAuthenticationButton {
      margin-top: 12px;
      width: 100%;
      min-width: 210px;
    }

    span {
      @include font(2, 'medium');
      color: $darkBlue !important;
      float: right;
      margin-top: 25px;
    }

    .verificationInfo {
      color: $textColor;
      @include font(2, 'regular');
      font-size: 14px !important;
      padding-bottom: 10px;
    }

    .verificationContainer {
      padding-top: 10px;
      padding-bottom: 20px;

      .verificationCode {
        display: block;
      }
    }
    .errorContainer {
      display: flex;

      div {
        padding-left: 10px;
      }
    }

    .resendLink {
      cursor: pointer;
    }
    .resendLink:hover {
      text-decoration: underline;
    }
  }

  .error {
    @include font(2);
    color: $error !important;
    margin-top: 6px;
  }

  .formControlError {
    border: 1px solid $error !important;
  }

  .logo {
    position: absolute;
    top: 3%;
    left: 5%;
    width: 150px;
  }

  .contactSection {
    position: absolute;
    top: 3%;
    right: 5%;

    span {
      @include font(2);
      color: $darkBlue !important;
      margin-right: 20px;
    }
  }
}

@media (max-width: 768px) {
  .contactSection {
    bottom: 3%;
    left: 5%;
    top: auto !important;
    right: auto !important;
  }

  .mFAuthentication span {
    float: left !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .mFAuthentication span {
    float: left !important;
  }
}
