@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.unitTagoutHistory {
  width: 100%;
  @include font(2);

  .fullWidth {
    .fullWidthWrapper {
      flex-direction: column;
    }
  }

  .pageContent {
    margin: 0px;
  }
  .tabHeader {
    padding-bottom: 20px;
  }

  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 4px;
    margin-left: 10px;
  }

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;

    .tagoutHistoryHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      button {
        @include font(3, 'bold');
        color: $lightBlue;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .subHeading {
      font-size: 20px;
      color: #085488;
    }
  }
}

.table {
  tbody tr {
    cursor: pointer;
    // margin: 0 20px 20px 0;
    // border-collapse: separate;
    // border-left: solid 6px $danger;
  }

  thead th {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey;
  }

  .empty {
    width: 100px;
    padding-left: 0px;
    background-color: $lightBlue;
  }

  .new {
    border-left: solid 6px $danger !important;
  }
  .pending {
    border-left: solid 6px $alert !important;
  }
  .resolved {
    border-left: solid 6px $success !important;
  }

  .dot {
    height: 12px;
    width: 12px;
    background-color: $danger;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  .dotNew {
    background-color: $danger;
  }
  .dotPending {
    background-color: $alert;
  }
  .dotResovled {
    background-color: $success;
  }

  .status {
    text-transform: capitalize;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }
}
