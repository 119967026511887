@import '../../styles/variables';
@import '../../styles/global';

.addChart {
  width: 100%;
  border-radius: 5px;
  border: none;
  @include boxShadow();
  background-color: $white;
  padding: 20px 40px;
  @include font(3);
  text-align: left;
  cursor: pointer;

  img {
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
}

.chartbutton {
  width: 200px !important;
  margin-bottom: 20px !important;
  margin-left: 20px !important;
  min-height: 50px;
  height: 100%;
}
