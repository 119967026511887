@import '../../../styles/variables';
@import '../../../styles/global';

.faq {
  @include font(2, 'medium');
  .contentWrapper {
    .faqCategory {
      margin-top: 30px;
    }
    .questionsContent {
      width: 100%;
      border-radius: 5px;
      margin-top: 20px;
      background-color: $white;
      box-sizing: border-box;
      position: relative;
      padding: 40px 50px;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .collapseImage {
        margin-right: 10px;
        margin-bottom: 5px;
      }

      .questionContainer {
        margin-bottom: 10px;
      }

      .question {
        color: $darkBlue !important;
        margin-bottom: 5px;
      }

      .answerContainer {
        margin-bottom: 20px;
      }

      .answer {
        font-weight: normal !important;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }
    }
  }
}
