@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.wrapper {
  margin: auto;
  //align-self: center;
  //position: relative;
  width: 80%;

  .login {
    margin: auto;
    align-self: center;
    position: relative;
    width: 70%;
  }

  .error {
    @include font(2);
    color: $error !important;
    margin-top: 6px;
  }

  .formControlError {
    border: 1px solid $error !important;
  }

  .logo {
    position: absolute;
    top: 3%;
    left: 5%;
    width: 150px;
  }
}
