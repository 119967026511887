@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.sequenceVersions {
  width: 100%;
  @include font(2);

  .fullWidth {
    .fullWidthWrapper {
      flex-direction: column;
    }
  }

  .backLink {
    @include font(2, 'medium');
    margin-top: 1rem;
    color: $darkBlue !important;
    margin-bottom: 15px;
    display: block;
  }

  .pageContent {
    margin: 0px;
  }

  .tabHeader {
    padding-bottom: 20px;
  }

  .sequenceCodeHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 15px;

    .subHeading {
      font-size: 20px;
      color: #085488;
    }

    button {
      @include font(3, 'bold');
      color: $lightBlue;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;

    .subHeading {
      font-size: 20px;
      color: #085488;
    }

    .addbutton {
      width: 180px !important;
      float: right;
    }

    .topColumns {
      max-width: 250px;
    }
    @media (max-width: 768px) {
      .topColumns {
        max-width: 100%;
      }
    }
  }
}

.table {
  tbody tr {
    cursor: pointer;
    // margin: 0 20px 20px 0;
    // border-collapse: separate;
    // border-left: solid 6px $danger;
  }

  thead th {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey;
  }

  .empty {
    width: 100px;
    padding-left: 0px;
    background-color: $lightBlue;
  }

  .active {
    border-left: solid 6px $danger !important;
  }
  .complete {
    border-left: solid 6px $success !important;
  }

  .dot {
    height: 12px;
    width: 12px;
    background-color: $danger;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  .dotActive {
    background-color: $danger;
  }
  .dotComplete {
    background-color: $success;
  }

  .status {
    text-transform: capitalize;
  }

  .btnView {
    float: right;
  }

  th {
    padding-left: 10px !important;
  }

  td {
    padding-left: 10px !important;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }
  .viewButton {
    width: 102px;
    margin: 5px;
  }
  .overDue {
    padding-left: 5px;
    font-style: italic;
    color: $grey;
  }
}

@media (max-width: 768px) {
}
