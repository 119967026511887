@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.height100 {
  height: 100%;
  overflow-y: hidden; /* don't show content that exceeds my height */
}

.imageSectionNoAuth {
  background-image: url('../../images/water-noauth.jpg');
  width: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .logoContainer {
    text-align: center !important;
    width: 50% !important;
  }

  p {
    @include font(4);
    color: $white !important;
    text-align: center;
    margin-top: 30px;
    overflow-wrap: break-word;
  }
}

.textBottomImage {
  position: absolute;
  bottom: 1%;

  p {
    @include font(2, medium);
    text-align: center;
  }

  a {
    color: $white;
  }

  a:hover {
    text-decoration: none;
    color: $white;
  }
}

.contentWrapper {
  @include content;
  height: 100%;
}

@media (max-width: 1024px) {
  .imageSectionNoAuth {
    .logoContainer {
      width: 80% !important;
    }
  }
}
