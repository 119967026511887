@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.componentParts {
  width: 100%;
  @include font(2);

  .fullWidth {
    .fullWidthWrapper {
      flex-direction: column;
    }
  }

  .pageContent {
    margin: 0px;

    .contentWrapper {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 20px;

      .componentPartHeader {
        justify-content: space-between;
        margin-bottom: 20px;

        .backLink {
          @include font(2, 'medium');
          color: $darkBlue !important;
          margin-bottom: 15px;
          display: block;
          text-transform: capitalize !important;
        }
      }

      .componentPartContent {
        width: 100%;
        border-radius: 6px;

        background-color: $white;
        padding: 30px;
        position: relative;
        span {
          @include font(2, 'medium');
        }
      }
      .addbutton {
        width: 180px;
      }
    }
  }
}
