@import '../../../styles/variables';
@import '../../../styles/global';

.fileUploader {
  width: 100%;
  position: relative;

  .backLink {
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-bottom: 15px;
    display: block;
  }

  .uploadContent {
    width: 100%;
    border-radius: 5px;
    background-color: $white;
    padding: 25px;
    box-sizing: border-box;
    position: relative;
    margin-top: 35px;
  }
  .messagebox {
    margin-top: 35px;
    .messageText {
      @include font(3, 'normal');
      color: $textColor;
    }
  }
  .uploadicon {
    color: #d3dbdf;
  }

  .btnUpload {
    margin-top: 5px;
    height: 36px !important;
  }

  .btnNext {
    margin-left: 5px;
    margin-top: 5px;
    height: 36px !important;
    z-index: 20;
  }

  .btnUploadNext {
    position: relative;
    cursor: pointer;
    height: 36px !important;
    z-index: 20;
    margin-left: 5px;
    margin-top: 5px;
  }

  .error {
    color: #e24545;
  }
  .textDrag {
    @include font(5, 'normal');
    margin: 20px 0px;
    color: $grey !important;
  }
  .or {
    @include font(2, 'normal');
    margin-bottom: 20px;
    color: $grey !important;
  }
  .texFile {
    @include font(3, 'normal');
    margin: 20px 0px;
    text-align: center;
    color: $grey !important;
  }
}

.fileupload {
  text-align: center !important;
  padding: 0px 25px;
  color: $textColor;

  .successTick {
    color: #01bf9b;
  }

  .greenTick {
    color: #01bf9b;
    height: 20px;
  }

  .error {
    color: #e24545;
    height: 20px;
  }

  .errorCross {
    color: #e24545;
  }
  .removeBtn {
    color: #e24545;
    cursor: pointer;
  }

  .btnfileUpload {
    height: 50px !important;
    max-width: 250px;
    width: 100%;
  }

  div {
    padding: 5px 5px;
  }
  .rowFile {
    background-color: #f7f6f6;
    margin-top: 3px;
  }
  .textMediumMessageAlignLeft {
    @include font(4, 'normal');
    color: $textColor;
    text-align: left;
  }
  .textMediumMessage {
    @include font(4, 'normal');
    color: $textColor;
  }
  .spinner {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.progressBar {
  width: 100%;
  height: 1px;
  background-color: $bgProgressbar;
  border-radius: 7px;
  margin: 50;
  padding: 1px 1px !important;

  .fillerStyles {
    height: 100%;
    background-color: $bgProgressbarFiller;
    border-radius: 7px;
    text-align: right;
    padding: 1px 1px !important;
  }

  .labelStyles {
    @include font(3, 'medium');
    position: absolute;
    margin-top: -25px;
    padding: 5;
    color: $bgProgressbarFiller !important;
  }
}
