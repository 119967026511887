@import '../../styles/inputs.module';

.timezoneSelect {
  @include font(3);
  line-height: initial;
  width: 100%;
  padding-left: 8px;
  color: $textColor;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  @include sharedInputStyle();

  &::-ms-expand {
    display: none;
  }
}
