@import '../../styles/variables';
@import '../../styles/global';

.alignCenter {
  text-align: center;
}

.seeAllUnits {
  margin-top: 60px;
}
.mapContainer {
  width: 100%;
}

.deviceWrapper {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 20px;

  li {
    width: 100%;
    max-height: 315px;
    margin: 0px !important;
    border-radius: 6px;
    box-sizing: border-box;
  }

  li:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 42%;

    border-top: 15px solid transparent;
    border-top-color: $white;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }

  div {
    width: 100%;
  }
}

.searchBarContainer {
  width: 260px !important;
  margin-left: 15px !important;
  margin-right: -35px !important;
}

.mapDropdown {
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
}

.fullScreenSearch {
  margin-left: 0px !important;
  margin-right: 0px !important;
  position: relative;
  z-index: 100;
  div {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-left: 0px;
    margin-right: 0px !important;
  }
}

@media (max-width: 768.99px) {
  .deviceWrapper {
    li {
      width: calc(100% - 10px) !important;
      margin: 0 0px 20px 0 !important;
    }
  }

  .mapContainerStyle {
    margin-left: -30px;
    width: 100vw !important;
  }

  .mapContainerStyleFullScreen {
    margin-left: 0px;
    width: 100vw !important;
    height: 100vh !important;
    margin-top: -80px;
  }

  .seeAllUnits {
    margin-top: 5px;
  }

  .mapContainer {
    margin-top: -14px;
  }

  .dropdownClass {
    margin-left: -15px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .contentWrapper {
    h2 {
      font-size: 24px !important;
    }
  }
}
