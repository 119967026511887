@import '../../styles/variables';
@import '../../styles/global';

.unitReports {
  width: 100%;

  .fullWidth {
    @include fullWidth;
    background-color: $white;

    .fullWidthWrapper {
      @include content;
    }

    h1 {
      @include font(8, 'light');
      padding: 30px 0;
    }
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .unitReportsHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .unitReportsContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .unitsubHeading {
        color: $darkBlue;
        font-size: 20px;
        padding-bottom: 20px;
      }

      .timeDdl {
        max-width: 130px;
      }

      .timeDdlError > div {
        max-width: 130px;
        border: 1px solid $error;
      }

      .subHeading2 {
        height: 18px !important;
        font-size: 15px !important;
        margin-bottom: 15px;
      }

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .btnCancel {
        margin: 0px 5px;
        height: 50px !important;
        padding-top: 12px;
      }

      .error {
        @include font(2);
        color: $error !important;
      }
      .divWarning {
        margin-bottom: 20px;
        display: flex;
        align-content: center;
        align-items: center;
      }
      .icon {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }

  .unitReportsCheckbox {
    .outerCol {
      border: solid 1px $border;

      margin-bottom: 15px;
    }

    .subHeading {
      @include font(2, 'medium');
      font-size: 20px !important;
      color: $darkBlue !important;
    }

    ul > li {
      vertical-align: middle;
      display: inline-block;
      width: 25rem;
      border-top-right-radius: 9px;
      border-top-left-radius: 9px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: $white;
      border-top: 6px solid $white;
      border-bottom: 1px solid $white;
      height: 53px;
      padding-left: 15px;
      align-items: center;

      &.selected {
        background-color: $greyBackground !important;
      }
    }
  }
}

.inputWrapper {
  display: flex;
  margin-bottom: 40px;

  label {
    @include font(1);
  }

  input {
    display: block;
    @include font(3);
    line-height: initial;
    color: $textColor;
    padding: 0.6em 1.4em 0.5em 0.8em;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  input[type='number'] {
    background-image: none;
  }
  input[type='text'] {
    background-image: none;
  }

  .row {
    width: 100%;
  }
  .label {
    width: 100%;
  }

  @mixin sharedInputStyle {
    min-height: 44px;
    background-color: $lightBlue;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230081b3%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 11px 11px;
    box-sizing: border-box;
    border: 1px solid $border;
    margin: 6px 30px 0 0;
  }
}
