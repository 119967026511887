@import '../../../styles/variables';
@import '../../../styles/global';

.notification {
  position: fixed;
  top: 2%;
  left: 1%;
  z-index: 999999;
  width: 97%;
  transition: top 0.2s linear;

  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 2px 3px 5px 0px $grey;
  background-color: #ffffff;
  border-left: solid 6px #2f92d0;

  .alert {
    margin-bottom: 0px !important;
  }

  &.enter {
    top: 10px;
  }

  &.error {
    border-left: solid 6px $danger !important;
  }
  &.warning {
    border-left: solid 6px $alert !important;
  }
  &.success {
    border-left: solid 6px $success !important;
  }

  .icon {
    padding-right: 10px;
    margin-bottom: 4px;
  }
}
