@import '../../styles/variables';
@import '../../styles/global';

.fullWidth {
  .fullWidthWrapper {
    flex-direction: column;
  }
}

.pageContent {
  margin: 0px;
}

.tabHeader {
  padding-bottom: 20px;
}
