@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.componentParts {
  width: 100%;
  @include font(2);

  .fullWidth {
    .fullWidthWrapper {
      flex-direction: column;
    }
  }

  .pageContent {
    margin: 0px;

    .contentWrapper {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 20px;

      .componentPartHeader {
        justify-content: space-between;
        margin-bottom: 20px;

        .backLink {
          @include font(2, 'medium');
          color: $darkBlue !important;
          margin-bottom: 15px;
          display: block;
          text-transform: capitalize !important;
        }
      }

      .componentPartContent {
        width: 100%;
        border-radius: 6px;

        background-color: $white;
        padding: 30px;
        position: relative;
        span {
          @include font(2, 'medium');
        }
        .serialNumberInfo {
          font-style: italic;
          font-size: small;
        }
      }

      .switch {
        margin-left: 0px;
        padding-left: 0px;
        label {
          margin-left: 0px;
          padding-left: 0px;
        }
        span {
          padding-left: 10px;
        }
      }

      .savebutton {
        width: 230px;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .btnCancel {
        width: 230px;
        margin: 0px 5px;
        height: 50px !important;
      }
    }
  }
}
