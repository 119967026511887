@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.profile {
  width: 100%;
  @include font(2);

  .fullWidth {
    @include fullWidth;
    background-color: $white;

    h1 {
      @include font(8, 'light');
      padding: 30px 0;
    }
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .profileHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      button {
        // @include font(3, 'bold');
        color: $lightBlue;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .profileContent {
      width: 100%;
      border-radius: 5px;
      @include boxShadow();
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }
    }

    .addbutton {
      padding-top: 12px !important;
      width: 194px !important;
    }
    .subHeading {
      font-size: 20px;
      color: #085488;
    }
  }
}
