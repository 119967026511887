@import '../../../styles/variables';
@import '../../../styles/global';

@mixin sharedInputStyle {
  min-height: 44px;
  background-color: $lightBlue;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"><path fill="none" fill-rule="evenodd" stroke="#085488" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 10.5L12 14.5 8 10.5"/></svg>');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 11px 11px;
  box-sizing: border-box;
  border: 1px solid $border;
  margin: 6px 30px 0 0;
}

.multiSelect {
  :global {
    .multi__control {
      width: 100%;
      @include sharedInputStyle();
    }
    .multi__indicators {
      display: none;
    }
    div[class*='control'] {
      min-height: 50px;
      height: 100%;
    }
    div[class*='multiValue'] {
      background-color: $darkBlue !important;
      color: white !important;
      border-radius: 3px;
    }
    div[class*='multiValue'] div {
      @include font(2);
      color: white !important;
    }
    .multi__option {
      @include font(2);
    }
    input {
      min-height: 0;
      margin: auto;
      background-color: $lightBlue;
    }
    span[class*='indicatorSeparator'] {
      background-color: $white;
    }
    div[class*='indicatorContainer'] svg {
      fill: $darkBlue;

      path {
        d: path(
          'M4.516 7.248c0.236-0.246 0.043-0.481 1.276 0l3.908 3.747 3.908-3.347c0.533-0.481 0.141-0.446 1.574 0 0.136 0.145 0.108 0.197 0 1.215-0.106 0.118-4.295 3.502-4.195 3.502-0.117 0.123-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.135c0 0-4.287-4.084-4.695-4.502s-0.136-1.17 0-1.615z'
        ) !important;
      }
    }
  }
}
