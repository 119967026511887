@import '../../styles/variables';
@import '../../styles/global';

// .modelfirmwareversion {
//   width: 100%;
//   @include font(2);

//   .contentWrapper {
//     width: 100%;
//     margin-top: 20px;
//     margin-bottom: 20px;

//     .modelfirmwareversionHeader {
//       justify-content: space-between;
//       margin-bottom: 20px;

//       .backLink {
//         @include font(2, 'medium');
//         color: $darkBlue !important;
//         margin-bottom: 15px;
//         display: block;
//       }

//       .btnPublishVersion {
//         width: unset;
//         height: 50px;
//       }

//       .alignRight {
//         text-align: right;
//       }
//     }

//   }
// }

.modelfirmwareversion {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .modelfirmwareversionHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .btnUpdateShadow {
      width: unset;
      height: 50px;
    }

    .titleRows {
      padding-top: 20px;
      padding-left: 18px;
      padding-right: 15px;

      .innerComponent {
        width: 100%;
        border-radius: 6px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
        background-color: $white;
        box-sizing: border-box;
        position: relative;
      }

      img {
        margin-right: 10px;
      }

      h4 {
        padding-top: 2px;
      }
      .link {
        cursor: pointer;
      }
    }

    .bottomRow {
      padding-bottom: 50px;
      padding-left: 18px;
      padding-right: 15px;
    }
    .alignRight {
      text-align: right;
    }

    @media (max-width: 768px) {
      .btnUpdateShadow {
        width: 100% !important;
      }
    }
  }

  .modelfirmwareversionContent {
    width: 100%;
    border-radius: 5px;

    background-color: $white;
    padding: 40px;
    box-sizing: border-box;
    position: relative;

    .intro {
      @include font(3);
      margin-bottom: 20px;
    }

    .margin5 {
      margin: 0px 5px;
    }

    .borderBox {
      border: 1px solid $border;
      margin-left: 15px;
      margin-bottom: 40px;
    }

    .btnSaveChanges {
      width: 230px !important;
    }

    .btnCancel {
      width: 230px;
      margin: 0px 5px;
      height: 50px !important;
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .ddlError > div {
      border: 1px solid $error;
    }

    .formControl {
      margin-bottom: 0px;
    }

    .formControlError {
      border: 1px solid $error !important;
    }

    .innerTable {
      padding: 1.25rem;
      vertical-align: top;
      border-top: none;
      font-size: 15px;
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 15px !important;

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: none;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .btnCol {
        text-align: right;
      }
      .changeButton {
        margin-top: 10px;
        max-width: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .removeButton {
        margin-top: 10px;
        float: right;
        max-width: 100px;
        margin-right: 20px;
      }
    }

    .wsaDropdown {
      width: 100%;
      padding-bottom: 10px;

      div[class*='control'] {
        height: 48px;
      }

      div[class*='menu-list'] {
        div[class*='option'] {
          border-bottom: solid 1px $border;
        }
      }

      .textArea {
        height: auto !important;
      }

      span[class*='indicatorSeparator'] {
        background-color: $white;
      }
    }
  }
}
