@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.telemetryLink {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .telemetryLinkHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .telemetryLinkContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .btnSaveChanges {
        width: 230px !important;
      }

      .btnCancel {
        width: 230px;
        margin: 0px 5px;
        height: 50px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .wsaDropdown {
        width: 100%;
        padding-bottom: 10px;

        div[class*='control'] {
          height: 48px;
        }

        div[class*='menu-list'] {
          div[class*='option'] {
            border-bottom: solid 1px $border;
          }
        }

        .textArea {
          height: auto !important;
        }

        span[class*='indicatorSeparator'] {
          background-color: $white;
        }
      }
    }

    .topRow {
      padding: 30px;
    }

    .addbutton {
      float: right;
    }

    .innerTable {
      padding: 1.25rem;
      vertical-align: top;
      border-top: none;
      font-size: 15px;
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 0px !important;

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: none;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .btnCol {
        text-align: right;
      }

      .changeButton {
        max-width: 100px;
        margin-top: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .icon {
        cursor: pointer;
      }
    }

    @media (max-width: 768px) {
      .addbutton {
        width: 100% !important;
      }
      .changeButton {
        float: left !important;
        width: 50px !important;
        margin-right: 0px !important;
        font-size: 10px !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
  }
}
