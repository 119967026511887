@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.chartWrapper {
  width: 100%;
  margin-bottom: 40px;

  .chartHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .chartbuttons {
      display: flex;
    }

    button {
      color: $darkBlue;
      display: block;
      border: none;
      background-color: transparent;
      text-transform: uppercase;
      cursor: pointer;
      padding-left: 20px;
    }
    .exportButton {
      color: $darkBlue;
      display: block;
      border: none;
      background-color: transparent;
      text-transform: uppercase;
      cursor: pointer;
      padding-left: 20px;
      padding-top: 6px;
    }

    a:hover {
      text-decoration: none;
    }
  }

  .chartContent {
    width: 100%;
    border-radius: 5px;
    background-color: $white;
    padding: 40px;
    box-sizing: border-box;
    position: relative;

    .intro {
      @include font(3);
      margin-bottom: 20px;
    }

    .divWarning {
      font-size: 12px !important;
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      align-items: center;
    }
    .icon {
      cursor: pointer;
      margin-right: 10px;
    }

    .error {
      color: $error;
    }
    .chartSlider {
      padding: 20px;
    }
  }
}
