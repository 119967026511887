@import '../../styles/variables';
@import '../../styles/global';

.pagination {
  width: 100%;

  .controls {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .pageLinks {
      display: flex;
      align-items: flex-start;
    }

    button {
      padding: 6px 10px;
      border-radius: 3px;
      border: 1px solid $lightBlue;
      @include font(1);
      margin: 0 5px;
      position: relative;
      height: 34px;
      width: 90px;

      &.numbers {
        width: 50px;
      }

      &.active {
        background-color: $lightBlue;
        color: $white;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover:not(.active) {
        &:not([disabled]) {
          background-color: $background;
          border: 2px solid $lightBlue;
        }
      }

      &[disabled] {
        cursor: default;
        opacity: 0.5;
      }
    }
  }
}
