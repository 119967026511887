@import './variables';

$f-fallback: Helvetica, Arial, sans-serif;
$f-regular-text: 'Barlow';

$font-scale: (
  (0.75rem, 1.25rem),
  (0.938rem, 1.5rem),
  (1rem, 1.75rem),
  (1.25rem, 1.75rem),
  (1.5rem, 2rem),
  (1.875rem, 2.5rem),
  (2.25rem, 3rem),
  (2.5rem, 3.25rem),
  (2.75rem, 3.5rem)
) !default;

@mixin font($size, $type: 'regular') {
  $family: $f-regular-text;
  $weight: 400;

  @if ($type == 'light') {
    $weight: 300;
  } @else if ($type == 'medium') {
    $weight: 500;
  } @else if ($type == 'semi-bold') {
    $weight: 600;
  } @else if ($type == 'bold') {
    $weight: 700;
  }

  font-family: $family, $f-fallback;
  font-size: nth(nth($font-scale, $size), 1) !important;
  line-height: nth(nth($font-scale, $size), 2) !important;
  font-weight: $weight !important;
  color: $textColor !important;
}

@mixin content {
  display: flex;
  max-width: 1440px;
  width: 100%;
  //padding: 0 125px;
  box-sizing: border-box;
  margin: auto;
}

@mixin fullWidth {
  left: 40%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

@mixin boxShadow {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
}
