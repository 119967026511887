@import '../../../styles/variables';
@import '../../../styles/global';
@import '../../../styles/media-queries';

.notifications {
  width: 100%;
  @include font(2);

  .circle {
    height: 27px;
    width: 34px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 4px;
    margin-left: 10px;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .notificationsHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      button {
        @include font(3, 'bold');
        color: $lightBlue;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .changeButton {
      float: right;
      width: 100px;
      margin-right: 20px;
    }

    @media (max-width: 575.99px) {
    }

    .subHeading {
      font-size: 20px;
      color: #085488;
    }
  }
}
