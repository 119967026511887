@import '../../styles/variables';
@import '../../styles/global';

.fullWidth {
  .fullWidthWrapper {
    flex-direction: column;
  }
}

.pageContent {
  margin: 30px 0 50px;
}

.alert {
  margin-bottom: 30px !important;
  height: 40px;

  img {
    margin-right: 5px;
  }
  span {
    @include font(2, 'medium');
    color: $textColor;
  }
}
