@import '../../styles/variables';
@import '../../styles/global';

.commandButton {
  margin-bottom: 10px;
  height: 50px !important;
  width: 100%;

  &:hover {
    &:not([disabled]) {
      background-color: $background;
      border: solid 2px $lightBlue;
    }
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}

@media (max-width: 768px) {
  .commandButton {
    line-height: 1.4 !important;
    width: 90% !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .commandButton {
    line-height: 1.4 !important;
    width: 90% !important;
  }
}

@media (max-width: 390px) {
  .commandButton {
    font-size: 11px !important;
    padding: 8px 3px !important;
  }
}
