@import '../../../styles/variables';
@import '../../../styles/global';

.wsaDropdown {
  width: 100%;
  padding-bottom: 10px;

  div[class*='control'] {
    height: 48px;

    div:nth-child(2) {
      width: unset !important;
      padding-top: 3px;
    }
  }

  div[class*='menu-list'] {
    div[class*='option'] {
      border-bottom: solid 1px $border;
    }
  }

  div[class*='placeholder'] {
    color: #3d3d41;
    font-size: 15px;
    margin-left: 9px;
  }

  span[class*='indicatorSeparator'] {
    background-color: $white;
  }

  div[class*='IndicatorsContainer'] {
    width: unset !important;
  }

  div[class*='indicatorContainer'] svg {
    fill: none;
    fill-rule: evenodd;
    stroke: #085488;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5;
    path {
      d: path('M16 10.5L12 14.5 8 10.5') !important;
    }
  }
}
