@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.modelFirmwareVersion {
  width: 100%;
  @include font(2);

  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 4px;
    margin-left: 10px;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .topRow {
      padding: 30px;
    }
    .modelVersionHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      button {
        @include font(3, 'bold');
        color: $lightBlue;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .modelVersionContent {
      width: 100%;
      border-radius: 5px;
      @include boxShadow();
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }
    }

    .innerTable {
      padding: 1.25rem;
      vertical-align: top;
      border-top: none;
      font-size: 15px;
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 0px !important;

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: none;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .btnCol {
        text-align: right;
      }
      .changeButton {
        margin-top: 10px;
        max-width: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .removeButton {
        margin-top: 10px;
        float: right;
        max-width: 100px;
        margin-right: 20px;
      }
    }

    .addbutton {
      width: 194px !important;
      float: right;
    }

    @media (max-width: 575.99px) {
      .addbutton {
        width: 100% !important;
      }
    }

    .subHeading {
      font-size: 20px;
      color: #085488;
    }
  }
}
