@import './variables';
@import './global';

.table {
  width: 100%;

  tr {
    &:nth-child(even) {
      background: $background;
    }

    &:nth-child(odd) {
      background: $white;
    }

    &.manualCommand {
      background-color: $lightBlue;
    }

    &.alarm {
      background-color: $alert;
    }

    &.error {
      background-color: $error;
    }

    &.manualCommand,
    &.alarm,
    &.error {
      td,
      td span {
        color: $white;
      }
    }

    th {
      @include font(1);
      opacity: 0.75;
      padding: 0px 10px 8px;
      text-align: left;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 30px;
      }
    }

    td {
      border-left: 2px solid $tableBorder;
      @include font(2);
      padding: 8px 10px;

      &:first-child {
        border-left: none;
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 30px;
      }

      .date {
        display: inline;
        opacity: 0.75;
        margin-left: 4px;
        @include font(1);
      }
    }
  }
}
