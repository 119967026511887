@import '../../../styles/variables';
@import '../../../styles/global';

.wsaCheckbox {
  ul.checkbox {
    margin: 0;
    padding: 0;
    margin-left: 0px;
    list-style: none;
  }

  ul.checkbox li input {
    margin-right: 0.25em;
  }

  ul.checkbox li {
    border: 1px transparent solid;
    display: inline-block;
    width: 25em;
  }

  ul.checkbox li label {
    margin-left: 0px;
  }

  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    @include font(2, 'medium');
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    border: solid 1px $white;
    border-radius: 5px;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $white;
    border-radius: 5px;
    border: solid 1px $border;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: $white;
    border-radius: 5px;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $lightBlue;
    border-radius: 5px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 8px;
    height: 12px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .selectall {
    font-weight: 600 !important;
    padding-bottom: 5px;
  }
  .wigetInfo {
    padding-bottom: 15px;
  }
}
