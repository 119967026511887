.hideMobile {
  @media (max-width: 480px) {
    display: none !important;
  }
}

.hideTablet {
  @media (min-width: 481px) and (max-width: 768px) {
    display: none !important;
  }
}

.hideDesktop {
  @media not all and (max-width: 768px) {
    display: none !important;
  }
}
