@import '../../styles/variables';
@import '../../styles/global';

.dataTrend {
  width: 100%;
  position: relative;
}

.chartOperation {
  display: flex;
  justify-content: flex-end;
}
