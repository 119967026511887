@import '../../styles/variables';
@import '../../styles/global';

.banner {
  position: fixed;
  top: 2%;
  left: 1%;
  z-index: 999999;
  width: 97%;
  transition: top 0.2s linear;

  &.enter {
    top: 0px;
  }
}
