@import '../../../styles/variables';
@import '../../../styles/global';

.progressBar {
  width: 100%;
  height: 14px;
  background-color: $bgProgressbar;
  border-radius: 7px;
  margin: 50;

  .fillerStyles {
    height: 100%;
    background-color: $bgProgressbarFiller;
    border-radius: 7px;
    text-align: right;
  }

  .labelStyles {
    @include font(3, 'medium');
    position: absolute;
    margin-top: -25px;
    padding: 5;
    color: $bgProgressbarFiller !important;
  }
}
