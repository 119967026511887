@import '../../styles/variables';
@import '../../styles/global';

.taskTemplate {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .taskTemplateHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .taskTemplateContent {
      width: 100%;
      border-radius: 6px;

      background-color: $white;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
      position: relative;

      .borderRight {
        border-right: 1px solid $border;
        padding-left: 60px !important;
      }

      .effort {
        @include font(2, 'medium');
      }
    }

    .changeButtonDiv {
      margin-top: 30px;
    }

    .btnChange {
      width: 230px !important;
    }
  }
}
