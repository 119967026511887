@import '../../styles/variables';
@import '../../styles/global';

.modelComponentType {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .modelComponentTypeHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .textArea {
      height: auto !important;
    }

    .modelComponentTypeContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
    }

    .btnSaveChanges {
      width: 190px !important;
    }

    .btnCancel {
      width: 190px;
      margin: 0px 5px;
      height: 50px !important;
    }
    .btnDelete {
      float: right;
      height: 50px !important;
    }
  }

  .subComponentContainer {
    margin-top: 50px;
    background-color: unset;

    .colAddButton {
      text-align: right;
    }

    .ddlTaskTemplate {
      max-width: 350px !important;
    }

    .modelSubComponentTypeRow {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .emptyRow {
      text-align: center;
      border-left: 8px solid #f7f6f6 !important;
    }

    .colBtn {
      text-align: right;
    }

    .modelSubComponentTypeViewButton {
      margin-right: 15px;
      margin-bottom: 15px;
    }

    .modelSubComponentTypeViewButton {
      max-width: 100px;
      margin-top: 10px;
      margin-right: 15px;
      margin-bottom: 15px;
    }

    .modelSubComponentTypeRemoveButton {
      max-width: 100px;
      margin-top: 10px;
      margin-right: 15px;
      margin-bottom: 15px;
      float: right;
    }

    .modelSubComponentTypeButton {
      margin-right: 15px;
      height: 36px !important;
    }

    .subHeading {
      margin-bottom: 20px;
    }

    h4 {
      display: inline;
    }

    .circle {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      border: solid 1px $darkBlue;
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-top: 8px;
      margin-left: 10px;
    }

    .btnCancel {
      width: 190px;
      margin: 0px 5px;
      height: 50px !important;
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .ddlError > div {
      border: 1px solid $error;
    }

    .formControl {
      margin-bottom: 0px;
    }

    .formControlError {
      border: 1px solid $error !important;
    }
  }
}
