@import '../../styles/variables';
@import '../../styles/global';

.toggleSwitchWrapper {
  @include font(2);
  flex: 0 0 auto;
  padding-left: 10px;

  .toggleSwitch {
    position: relative;
    width: 36px;
    height: 20px;
    display: inline-block;
    margin-left: 10px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked {
        & + .slider:before {
          transform: translateX(14px);
        }

        & + .slider {
          background-color: $darkBlue;
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $border;
      transition: 0.1s;
      border-radius: 20px;

      &:before {
        position: absolute;
        content: '';
        height: 14px;
        width: 14px;
        left: 4px;
        bottom: 3px;
        background-color: $white;
        transition: 0.1s;
        border-radius: 50%;
      }
    }
  }
}
