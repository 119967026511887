@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.staticDataListing {
  width: 100%;
  @include font(2);

  .fullWidth {
    .fullWidthWrapper {
      flex-direction: column;
    }
  }

  .pageContent {
    margin: 0px;
  }

  .tabHeader {
    padding-bottom: 20px;
  }

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;

    .staticDataHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      button {
        @include font(3, 'bold');
        color: $lightBlue;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .subHeading {
      font-size: 20px;
      color: #085488;
    }

    .colActionButton {
      float: right;
      margin-right: 0px;
      align-items: center;
    }
    .wsaDropdownBulkActions {
      div[class*='control'] {
        border-color: $lightBlue;
      }
    }

    .addbutton {
      width: 180px !important;
      float: right;
    }

    .margin5 {
      margin: 0px 5px;
    }

    .error {
      @include font(2);
      color: $error !important;
    }
    .formControlError {
      border: 1px solid $error !important;
    }

    .controlWidth {
      width: 95px;
      height: 50px !important;
    }
  }

  .table {
    tbody tr {
      cursor: pointer;
      // margin: 0 20px 20px 0;
      // border-collapse: separate;
      // border-left: solid 6px $danger;
    }

    thead th {
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey;
    }

    .empty {
      width: 100px;
      padding-left: 0px;
      background-color: $lightBlue;
    }

    .active {
      border-left: solid 6px $danger !important;
    }
    .complete {
      border-left: solid 6px $success !important;
    }

    .dot {
      height: 12px;
      width: 12px;
      background-color: $danger;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .dotActive {
      background-color: $danger;
    }
    .dotComplete {
      background-color: $success;
    }

    .status {
      text-transform: capitalize;
    }

    .btnView {
      float: right;
    }

    .sortIcon {
      height: 12.2px;
      width: 10px;
      margin-left: 8px;
    }
    .sortHeading {
      cursor: pointer;
    }
    .viewButton {
      width: 102px;
    }
    .overDue {
      padding-left: 5px;
      font-style: italic;
      color: $grey;
    }
  }

  @media (max-width: 768px) {
  }
}

.staticData {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .taskHeader {
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .section {
      padding-top: 20px;
    }

    .addbutton {
      width: 230px !important;
      float: right;
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .staticDataContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }
      .marginv30 {
        margin: 30px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }
      .btnSaveMainChanges {
        width: 150px !important;
        height: 50px !important;
      }
      .btnSaveChanges {
        width: 150px !important;
        height: 35px !important;
      }

      .floatRight {
        float: right;
      }
      .btnMainCancel {
        width: 150px;
        margin: 0px 5px;
        height: 50px !important;
      }
      .btnCancel {
        width: 150px;
        margin: 0px 2px;
        height: 35px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
      .textArea {
        height: auto !important;
      }
      .hours {
        float: left;
        margin-left: 8px;
        margin-top: 10px;
      }
      .dueDateTime {
        max-width: 200px;
      }
      .alarmAlert {
        height: 40px !important;

        img {
          margin-right: 8px;
        }
      }
      .removeLink {
        margin-top: 7px;
        cursor: pointer;
      }

      .removeLinkDisable {
        margin-top: 7px;
        color: $grey !important;
      }
    }

    .innerTable {
      padding: 1.25rem;
      vertical-align: top;
      border-top: none;
      font-size: 15px;
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 0px !important;

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: none;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .btnCol {
        text-align: right;
      }

      .changeButton {
        max-width: 100px;
        margin-top: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .removeButton {
        float: right;
        margin-top: 10px;
        max-width: 100px;
        margin-right: 20px;
      }

      .newRow {
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
      }

      .icon {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 768px) {
  .btnSaveChanges {
    margin-bottom: 20px !important;
  }
}
