@import '../../styles/variables';
@import '../../styles/global';

:global {
  .rdtPicker {
    z-index: 5 !important;

    td.rdtActive,
    td.rdtActive:hover {
      background-color: $lightBlue;
    }
    td.rdtToday:before {
      border-bottom: 7px solid $lightBlue;
    }
  }
}

.inputWrapper {
  display: flex;
  margin-bottom: 10px;
  white-space: nowrap;

  input {
    display: block;
    @include font(3);
    line-height: initial;
    color: $textColor;
    padding: 0.6em 1.4em 0.5em 0.8em;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  input[type='number'] {
    background-image: none;
  }
  input[type='text'] {
    background-image: none;
  }
  .dropdown {
    @include font(1);
  }

  .row {
    width: 100%;
  }
  .label {
    width: 100%;
  }
  .vAlign {
    margin: auto;
  }
  .next {
    width: 50px;
    // float: right;
    margin-right: 10px;
  }
  .label {
    color: $textColor;
    @include font(3, normal);
    //white-space: nowrap;
  }

  @mixin sharedInputStyle {
    min-height: 44px;
    background-color: $lightBlue;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230081b3%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 11px 11px;
    box-sizing: border-box;
    border: 1px solid $border;
    margin: 6px 30px 0 0;
  }
}

@media (max-width: 1365px) {
  .inputWrapper {
    input {
      padding: 0.6em 0.25em 0.5em 0.4em !important;
    }
  }
}
