@import '../../styles/variables';
@import '../../styles/global';

.inputlabel {
  width: 100%;
  @include font(3);
}
.label {
  padding-bottom: 5px !important;
  color: $textColor;
  @include font(3, normal);
  //white-space: nowrap;
}
