@import '../../styles/variables';
@import '../../styles/global';

.eventLog {
  width: 100%;

  .eventsList {
    margin: 10px;

    .sortIcon {
      height: 12.2px;
      width: 10px;
      margin-left: 8px;
    }
    .sortHeading {
      cursor: pointer;
    }

    .eventHeader {
      @include font(2, 'medium');
      color: $grey !important;
      padding-top: 30px;
    }
    .eventContent {
      padding-top: 18px;
      padding-bottom: 18px;
      border-bottom: solid 1px $greyBackground;
      @include font(2);

      img {
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .eventsList {
    margin-left: 0px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .eventsList {
    margin-left: 0px !important;
  }
}
