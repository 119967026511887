@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.unitStat {
  width: 100%;
  margin-bottom: 20px;

  .container {
    padding-top: 30px;
    padding-left: 18px;

    img {
      margin-right: 10px;
    }

    h3 {
      margin-right: 10px;
    }

    .statPeriodOptions {
      width: 250px;
      border-radius: 4px;
      border: solid 1px $border;
      background-color: $white;
      z-index: 10;
      position: absolute;
    }
    .statPeriodRows {
      margin-left: 0px;
      min-height: 50px;
      width: 100%;
      @include font(3, 'regular');
      border-top: solid 1px $border;
      color: $textColor;
      align-items: center;
      padding-right: 15px;
      background-color: $white;
      cursor: pointer;
    }
    .noBorder {
      border-top: 0px !important;
    }

    .statPeriodRows:hover {
      background-color: $lightBorder;
    }

    .subHeading {
      cursor: pointer;
    }
  }
  .updateDateTime {
    float: right;
    margin-top: -30px;
    margin-right: 15px;
    color: $grey;
  }

  .tiles {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
.unitStatDashboard {
  margin-bottom: 0px !important;
}
