@import '../../../styles/variables';
@import '../../../styles/global';

.notifications {
  .pageContent {
    margin: 0px;
  }

  .tabHeader {
    padding-bottom: 20px;
  }
}

.modalCustom {
  @include font(2);
  position: absolute !important;
  width: 25% !important;
  height: 100% !important;
  top: 0;
  right: 0;
  margin: 0 !important;
  text-align: center;
  max-height: 100% !important;

  .contentWrapper {
    width: 90%;
    margin-bottom: 20px;
    text-align: left;
    .message {
      font-size: 13px;
      color: $textColor;
    }
    .notificationSection {
      border-bottom: 1px solid $border;
      padding-bottom: 15px;
      padding-left: 20px;
      padding-top: 15px;

      .messageTitle {
        @include font(1, 'bold');
      }

      .flexContainer {
        display: flex;
      }

      .flexChildLeft {
        flex: 1;
      }
      .flexChildRight {
        flex: 8;
      }

      .flexChild:first-child {
        margin-right: 20px;
      }

      .messageStyle {
        padding: 4px 10px;
        border-radius: 25px;
        margin: 5px 0px;
        @include font(1, 'medium');
      }
      .danger {
        color: $dangerHover !important;
        background-color: $errorBackground !important;
      }
      .warning {
        color: $alert !important;
        background-color: $warning !important;
      }
      .success {
        color: $successHover !important;
        background-color: $alertSuccessLight !important;
      }
      .info {
        color: $darkBlue !important;
        background-color: $alertInfoLight !important;
      }

      .icon {
        margin-top: 6px;
        width: 25px;
      }
    }
    .notificationTime {
      color: $grey !important;
      font-style: italic;
      font-size: 14px !important;
    }
  }

  .tabNav {
    width: 100%;
    display: flex;
    border-bottom: 1px solid $border;
    padding-top: 13px;
    margin-bottom: 0px !important;

    li {
      padding-right: 30px;
      padding-left: 20px;

      a {
        @include font(2, 'medium');
        color: $grey !important;
        text-decoration: none;
        display: inline-block;
        padding: 0 0 0px 0px;
        opacity: 0.5;

        &:hover,
        &.active {
          opacity: 1;
          color: $darkBlue !important;
        }
      }

      span {
        display: block;
        background-color: $darkBlue;
        // width: 25%;
        height: 2px;
        border-radius: 7px;
        margin-top: 8px;
        padding-left: 0px !important;
      }
      .countRectangle {
        background: $danger;
        line-height: 17px !important;
        height: 17px;
        width: 14px;
        border-radius: 2px;
        font-size: 10px !important;
        color: $white !important;
        text-align: center;
        // border: 1px solid $white;
        z-index: 999999;
        display: inline-block;
        margin-left: 3px;
      }
      .active {
        color: $darkBlue !important;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 768px) {
  .modalCustom {
    width: 90% !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .modalCustom {
    width: 90% !important;
  }
}
