@import '../../styles/variables';
@import '../../styles/global';

.organisationHierarchy {
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  .contentWrapper {
    padding: 40px;
    border-radius: 5px;
    background-color: $white;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    h3 {
      @include font(3, 'bold');
      margin-bottom: 20px;
    }

    .formControl {
      margin-bottom: 0px;
    }
    .info {
      margin-bottom: 30px;

      .icon {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}

.exportRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}
