$lightBlue: #2f92d0;
$darkBlue: #085488;
$lighterBlue: #edf7f7;
$white: #ffffff;
$black: #000000;
$background: #f9fcfe;
$greyBackground: #f7f6f6;
$border: #d3dbdf;
$lightBorder: #dddddd;
$tableBorder: #dadada;

$textColor: #3d3d41;
$grey: #919191;
$alert: #f2aa3e;
$danger: #e24545;
$dangerHover: #c22323;
$error: #fb5252;
$success: #01bf9b;
$successHover: #099b7f;
$errorBackground: #fdf3f5;
$warning: #fff6e2;
$online: green;
$avatarBackground: #acd7d7;
$bgProgressbar: #f1f3f4;
$bgProgressbarFiller: #01bf9b;
$alertGreen: #ebf9f7;
$statisticsBlue: #3180b1;
$statisticsGreen: #18c298;
$statisticsViking: #5ac9dd;
$alertSuccessLight: #e8fffb;
$alertInfoLight: #edf0ff;
