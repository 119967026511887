@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.releaseNotes {
  width: 100%;
  @include font(2);

  .fullWidth {
    .fullWidthWrapper {
      flex-direction: column;
    }
  }

  .pageContent {
    margin: 0px;
  }
  ol,
  ul {
    list-style: revert;
  }

  .tabHeader {
    padding-bottom: 20px;
  }

  .contentWrapper {
    padding: 40px;
    border-radius: 5px;
    background-color: $white;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    h3 {
      @include font(3, 'bold');
      margin-bottom: 20px;
    }

    .formControl {
      margin-bottom: 0px;
    }
    .notes {
      margin-bottom: 30px;
      margin-top: 10px;
      margin-left: 20px;
      .icon {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}
