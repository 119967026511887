@import '../../styles/variables';
@import '../../styles/global';

.model {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .modelHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .modelContent {
      width: 100%;
      border-radius: 5px;
      margin-bottom: 20px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .subHeading {
        padding-bottom: 15px;
      }
      .modelVersionInfo {
        padding-bottom: 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .wsaDropdown {
        width: 100%;
        padding-bottom: 10px;

        div[class*='control'] {
          height: 48px;
        }

        div[class*='menu-list'] {
          div[class*='option'] {
            border-bottom: solid 1px $border;
          }
        }

        .textArea {
          height: auto !important;
        }

        span[class*='indicatorSeparator'] {
          background-color: $white;
        }
      }

      .innerTable {
        padding: 1.25rem;
        vertical-align: top;
        border-top: none;
        font-size: 15px;
        width: 100%;
        padding-bottom: 0px;
        margin-bottom: 0px !important;

        thead th {
          padding-bottom: 5px;
          background-color: $border;
          border: none !important;
          color: $textColor;
          height: 40px;
          vertical-align: middle !important;
          padding-top: 5px !important;
        }

        tbody tr {
          border: none;
        }

        tr td:first-child {
          font-weight: normal;
        }

        tbody tr td {
          border-top: 1px solid $border;
        }

        .alignMiddle {
          padding: revert;
        }
      }
    }

    .margin5 {
      margin: 0px 5px;
    }

    .btnSaveChanges {
      width: 230px !important;
    }

    .btnCancel {
      width: 230px;
      margin: 0px 5px;
      height: 50px !important;
    }
  }
}
