.sen-red {
  fill: #dc3545 !important;
}

.sen-amber {
  fill: #ffbf00 !important;
}

.sen-green {
  fill: #61b872 !important;
}
.sen-white {
  fill: #ffff !important;
}

.show-sensor {
  display: block !important;
}

.hide-sensor {
  display: none !important;
}

.voltage-font {
  font-weight: 600 !important;
  font-size: 100px !important;
  font-family: Arial !important;
  stroke-width: 1.10186 !important;
}
