@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.unitList {
  width: 100%;
  @include font(2);

  .fullWidth {
    .fullWidthWrapper {
      flex-direction: column;
    }
    .alertsRow {
      padding-left: 18px;
      align-items: center;

      img {
        margin-right: 5px;
      }

      span {
        @include font(2, 'medium');
      }
      .link:hover {
        text-decoration: none !important;
      }
    }
  }

  .pageContent {
    margin: 0px;
  }

  .tabHeader {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 4px;
    margin-left: 10px;
  }

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;

    .subHeading {
      font-size: 20px;
      color: #085488;
    }

    a:hover {
      text-decoration: none;
    }
    .searchBar {
      margin-right: 10px;
    }
  }

  .addbutton {
    max-width: 180px;
    height: 36px;
    margin-top: 20px;
  }
  .buttonSpace {
    padding-left: 20px;
  }
}

.table {
  tbody tr {
    cursor: pointer;
  }

  thead th {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey;
  }

  .empty {
    width: 100px;
    padding-left: 0px;
    background-color: $lightBlue;
  }

  .borderRed {
    border-left: solid 6px $danger !important;
  }

  .icon {
    margin-right: 5px;
    margin-top: 5px;
    width: 20px;
    display: inline;
  }

  .borderYellow {
    border-left: solid 6px $alert !important;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }
}
