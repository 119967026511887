@import '../../styles/variables';
@import '../../styles/global';

.approvedComponent {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .approvedComponentHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
      .colActionButton {
        float: right;
        margin-right: 0px;
      }
    }

    .textArea {
      height: auto !important;
    }

    .approvedComponentContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      hr {
        border-top: solid 1px $border;
        width: 120%;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: -25px;
        margin-right: -25px;
      }

      .switch {
        margin-left: 0px;
        padding-left: 0px;
        label {
          margin-left: 0px;
          padding-left: 0px;
        }
        span {
          padding-left: 10px;
        }
      }

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .btnSaveChanges {
        width: 190px !important;
      }

      .btnCancel {
        width: 190px;
        margin: 0px 5px;
        height: 50px !important;
      }
      .btnDelete {
        float: right;
        height: 50px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
      .dataSheetLInk {
        color: $textColor !important;
      }

      .innerTable {
        vertical-align: top;
        border-top: none;
        font-size: 15px;
        width: 100%;
        margin-top: 20px;
        padding-bottom: 0px;
        margin-bottom: 0px !important;

        thead th {
          padding-bottom: 5px;
          font-weight: 500;
          background-color: $border;
          border: none !important;
          color: $textColor;
          height: 40px;
          vertical-align: middle !important;
          padding-top: 5px !important;
        }
        .textResultInput {
          width: 125px;
          margin-bottom: 0px;
        }
        .textInput {
          width: 125px;
          height: 50px !important;
          @include font(2);
          line-height: 1.5rem !important;
          color: $textColor !important;
          border: 1px solid $border !important;
          margin-bottom: 0px;
          border-radius: 4px;
          padding: 2px 8px;
        }
        tbody tr {
          border: none;
        }

        tr td:first-child {
          font-weight: normal;
        }

        tbody tr td {
          border-top: 1px solid $border;
        }

        .emptyCol {
          width: 40%;
        }

        .cellClass {
          vertical-align: middle;
        }

        .errorCalCell {
          height: 50%;
        }

        .removeButton {
          float: right;
          width: 100px;
          margin-right: 20px;
        }
        .changeButton {
          float: right;
          width: 100px;
          margin-right: 20px;
        }

        .icon {
          cursor: pointer;
        }
      }
    }
  }
}
