@import '../../styles/variables';
@import '../../styles/global';

.tabNav {
  width: 100%;
  display: flex;
  border-top: 1px solid #d3dbdf;
  border-bottom: 1px solid #d3dbdf;
  padding-top: 13px;

  li {
    padding-right: 30px;

    a {
      @include font(2, 'medium');
      color: $grey !important;
      text-decoration: none;
      display: inline-block;
      padding: 0 0 0px 0px;
      opacity: 0.5;

      &:hover,
      &.active {
        opacity: 1;
        color: $darkBlue !important;
      }
    }

    span {
      display: block;
      background-color: $darkBlue;
      // width: 25%;
      height: 2px;
      border-radius: 7px;
      margin-top: 13px;
    }
  }
}
