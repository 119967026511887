.react-code-input {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']:focus {
    outline: none !important;
    box-shadow: 0 0 2px #719ece;
  }
}
