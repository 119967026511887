@import '../../styles/variables';
@import '../../styles/global';

.unitTagOut {
  width: 100%;
  padding: 40px 30px;
  background-color: $white;
  box-sizing: border-box;
  height: 100%;
  border-top: solid;
  border-top-width: 1px;
  border-top-color: $border;

  h6 {
    margin-bottom: 10px;
  }

  p {
    @include font(1);
  }

  .btnSaveChangesSmall {
    width: 100px !important;
    height: 36px !important;
  }

  .btnCancelSmall {
    width: 100px;
    margin: 0px 5px;
    height: 36px !important;
  }

  .margin5 {
    margin: 15;
  }

  .textArea {
    height: auto !important;
  }

  .formControlError {
    border: 1px solid $error !important;
  }

  .tagInText {
    font-size: 15px;
    padding: 10px;
  }

  .tagoutReason {
    color: $grey !important;
    font-size: 15px;
    padding: 10px;
  }

  .tagButton {
    height: 36px !important;
    width: 160px !important;
    font-size: 14px !important;
    margin: 5px 0px !important;
  }
}
