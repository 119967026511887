@import '../../styles/variables';
@import '../../styles/global';

.sequenceVersionFile {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .margin5 {
      margin: 0px 5px;
    }

    .btnSaveChanges {
      width: 230px;
      margin: 0px 5px 0px 0px !important;
    }

    .btnCancel {
      width: 230px;
      height: 50px !important;
    }

    .sequenceVersionFileHeader {
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .sequenceVersionFileContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 20px;

      .ddlError > div {
        border: 1px solid $error;
      }

      .sampleLink {
        float: right;
      }

      .jsonEditor {
        width: 100% !important;
        max-width: min(100%, 100vw) !important;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderRight {
        border-right: 1px solid $border;
        padding-left: 60px !important;
      }

      .subHeading {
        @include font(2, 'medium');
      }

      .dot {
        height: 12px;
        width: 12px;
        background-color: $danger;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }

      .dotActive {
        background-color: $danger;
      }

      .dotComplete {
        background-color: $success;
      }

      .sequenceVersionFileComment {
        width: 100%;
        border-radius: 5px;
        padding-top: 20px;
        background-color: $white;
        box-sizing: border-box;
        position: relative;

        .circle {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          display: inline-block;
          text-align: center;
          border: solid 1px $darkBlue;
          @include font(2, 'medium');
          color: $darkBlue !important;
          margin-top: 4px;
          margin-left: 10px;
        }
      }

      .divMaxWidth {
        max-width: 100px !important;
      }
    }

    .borderRed {
      border-left: solid 6px $danger !important;
    }

    .borderGreen {
      border-left: solid 6px $success !important;
    }

    .changeButtonDiv {
      margin-top: 30px;
    }

    .btnEdit {
      width: 120px !important;
    }

    .alarmAlert {
      height: 40px;

      img {
        margin-right: 8px;
      }
    }

    .btnMarkComplete {
      width: 240px;
      margin-bottom: 25px;
    }

    .actualEffort {
      cursor: pointer;
      text-decoration: underline;
    }

    .alarmLink {
      text-decoration: none;
    }

    .controlWidth {
      width: 80px;
      height: 40px !important;
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .formControlError {
      border: 1px solid $error !important;
    }

    .overDue {
      padding-left: 5px;
      font-style: italic;
      color: $grey;
    }
  }
}

@media (max-width: 768px) {
  .sequenceVersionFile {
    .contentWrapper {
      .sequenceVersionFileContent {
        .alarmAlert {
          height: auto !important;
        }

        .divMaxWidth {
          max-width: 82px !important;
        }
      }
    }
  }
}
