@import '../../../styles/variables';
@import '../../../styles/global';

.mainComponent {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .mainComponentHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .alignRight {
      text-align: right;
    }

    .mainComponentContent {
      width: 100%;
    }
    @media (max-width: 768px) {
      .btnUpdateShadow {
        width: 100% !important;
      }
    }
  }
}
