@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.tasks {
  width: 100%;
  @include font(2);

  .fullWidth {
    .fullWidthWrapper {
      flex-direction: column;
    }
  }

  .pageContent {
    margin: 0px;
  }

  .tabHeader {
    padding-bottom: 20px;
  }

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;

    .subHeading {
      font-size: 20px;
      color: #085488;
    }

    .colActionButton {
      float: right;
      margin-right: 0px;
      align-items: center;
    }
    .wsaDropdownBulkActions {
      div[class*='control'] {
        border-color: $lightBlue;
      }
    }

    .addbutton {
      width: 180px !important;
      float: right;
    }

    .margin5 {
      margin: 0px 5px;
    }

    .error {
      @include font(2);
      color: $error !important;
    }
    .formControlError {
      border: 1px solid $error !important;
    }

    .controlWidth {
      width: 95px;
      height: 50px !important;
    }
  }
}

.table {
  tbody tr {
    cursor: pointer;
    // margin: 0 20px 20px 0;
    // border-collapse: separate;
    // border-left: solid 6px $danger;
  }

  thead th {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey;
  }

  .empty {
    width: 100px;
    padding-left: 0px;
    background-color: $lightBlue;
  }

  .active {
    border-left: solid 6px $danger !important;
  }
  .complete {
    border-left: solid 6px $success !important;
  }

  .dot {
    height: 12px;
    width: 12px;
    background-color: $danger;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  .dotActive {
    background-color: $danger;
  }
  .dotComplete {
    background-color: $success;
  }

  .status {
    text-transform: capitalize;
  }

  .btnView {
    float: right;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }
  .viewButton {
    width: 102px;
  }
  .overDue {
    padding-left: 5px;
    font-style: italic;
    color: $grey;
  }
}

@media (max-width: 768px) {
}
