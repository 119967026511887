@import '../../styles/variables';
@import '../../styles/global';

.unitConfiguration {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-bottom: 20px;

    .modelversionHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .btnUpdateShadow {
      width: unset;
      height: 50px;
    }

    .alignRight {
      text-align: right;
    }

    .modelversionContent {
      width: 100%;

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 8px;
        margin-left: 10px;
      }

      .titleRows {
        padding-left: 18px;
        padding-right: 15px;

        .innerComponent {
          width: 100%;
          border-radius: 6px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
          background-color: $white;
          box-sizing: border-box;
          position: relative;

          .marginTop25 {
            margin-top: 25px;
          }

          .fontSize {
            font-size: 18px !important;
          }

          .unitModel {
            padding: 30px;
            span {
              @include font(2, 'medium');
            }
            button {
              width: 102px;
              float: right;
            }
            .saveButton {
              height: 36px !important;
              width: 102px !important;
              margin-left: 10px;
            }
            .updateButton {
              height: 36px !important;
              width: 220px !important;
              margin-left: 10px;
            }
            .formControlError {
              border: 1px solid $error !important;
              padding-left: 0px;
            }
            .frmCalendar {
              padding-left: 0px;
            }
            .error {
              @include font(2);
              color: $error !important;
              margin-top: 6px;
            }
          }
        }

        img {
          margin-right: 10px;
        }

        h4 {
          padding-top: 5px;
        }
        .link {
          cursor: pointer;
        }
        .ddlError > div {
          border: 1px solid $error;
        }
        .wsaDropdown {
          width: 100%;
          padding-bottom: 10px;

          div[class*='control'] {
            height: 48px;
          }

          div[class*='menu-list'] {
            div[class*='option'] {
              border-bottom: solid 1px $border;
            }
          }

          span[class*='indicatorSeparator'] {
            background-color: $white;
          }

          div[class*='indicatorContainer'] svg {
            fill: $darkBlue;
            path {
              d: path(
                'M4.516 7.248c0.236-0.246 0.043-0.481 1.276 0l3.908 3.747 3.908-3.347c0.533-0.481 0.141-0.446 1.574 0 0.136 0.145 0.108 0.197 0 1.215-0.106 0.118-4.295 3.502-4.195 3.502-0.117 0.123-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.135c0 0-4.287-4.084-4.695-4.502s-0.136-1.17 0-1.615z'
              ) !important;
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      .btnUpdateShadow {
        width: 100% !important;
      }
    }
  }
}
