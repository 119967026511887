@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.settings {
  width: 100%;

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      padding-top: 10px;
    }

    .subHeading {
      font-size: 20px;
      color: #085488;
    }
    .cachebutton {
      margin-top: 15px;
    }
    .note {
      @include font(2);
    }
  }
}
