@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.componentPartContent {
  width: 100%;
  border-radius: 6px;

  background-color: $white;
  padding: 30px;
  position: relative;
  margin-bottom: 20px;
  span {
    @include font(2, 'medium');
  }
  h4 {
    margin-bottom: 15px;
  }
}

.partsHistory {
  padding: 0px !important;
  h4 {
    padding-left: 30px;
    padding-top: 30px;
  }
}

.historyData {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .topRow {
      padding: 30px;
    }

    .innerTable {
      padding: 1.25rem;
      vertical-align: top;
      border-top: none;
      font-size: 15px;
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 0px !important;

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: none;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .btnCol {
        text-align: right;
      }
      .icon {
        cursor: pointer;
      }
      .sortIcon {
        height: 12.2px;
        width: 10px;
        margin-left: 8px;
      }
      .sortHeading {
        cursor: pointer;
      }
    }
  }
}
