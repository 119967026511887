@import '../../styles/variables';
@import '../../styles/global';

.modelComponentPosition {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .modelComponentPositionHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .subHeading {
      margin-bottom: 20px;
    }

    h4 {
      display: inline;
    }

    .circle {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      border: solid 1px $darkBlue;
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-top: 8px;
      margin-left: 10px;
    }

    .colAddButton {
      padding-bottom: 40px;
      .addButton {
        float: right;
      }
    }

    .innerTable {
      padding: 1.25rem;
      vertical-align: top;
      border-top: none;
      font-size: 15px;
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 0px !important;

      .emptyRow {
        text-align: center;
      }

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: none;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .btnCol {
        text-align: right;
      }
      .changeButton {
        margin-top: 10px;
        max-width: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .removeButton {
        margin-top: 10px;
        float: right;
        max-width: 100px;
        margin-right: 20px;
      }
    }

    .textArea {
      height: auto !important;
    }

    .modelComponentPositionContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      hr {
        border-top: solid 1px $border;
        width: 120%;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: -25px;
        margin-right: -25px;
      }

      .switch {
        margin-left: 0px;
        padding-left: 0px;
        label {
          margin-left: 0px;
          padding-left: 0px;
        }
        span {
          padding-left: 10px;
        }
      }

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .btnSaveChanges {
        width: 190px !important;
      }

      .btnCancel {
        width: 190px;
        margin: 0px 5px;
        height: 50px !important;
      }
      .btnDelete {
        float: right;
        height: 50px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .verticalAlign {
        display: flex;
        align-items: center;
        padding: 0px !important;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
    }
  }
}
