@import '../../../styles/variables';
@import '../../../styles/global';

.treeView {
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  ul {
    padding-left: 20px;
  }
  .treeNode {
    margin-bottom: 7px;
    margin-top: 7px;
    cursor: pointer;
    span {
      margin-left: 13px;
    }

    .logo {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .buttonClass {
      height: 25px;
      width: 85px;
      padding: 0px;
      margin-left: 30px;
    }

    .circle {
      height: 13px;
      width: 13px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      border: solid 1px $darkBlue;
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-top: 8px;
      margin-left: 10px;
    }
    .circleBlue {
      height: 13px;
      width: 13px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      border: solid 1px $darkBlue;
      background-color: $lightBlue;
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-top: 8px;
      margin-left: 10px;
    }
  }
}
