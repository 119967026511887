@import '../../styles/variables';
@import '../../styles/global';

.checklistTemplate {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .checklistTemplateHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .btnSaveChanges {
      width: 230px;
      margin: 0px 5px 0px 0px !important;
    }

    .btnCancel {
      width: 230px;
      height: 50px !important;
    }

    .colChangeButton {
      text-align: right;
    }

    .taskContainer {
      margin-top: 50px;

      .colAddButton {
        text-align: right;
      }

      .ddlTaskTemplate {
        max-width: 350px !important;
      }

      .taskRow {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .emptyRow {
        text-align: center;
        border-left: 0px !important;
      }

      .colBtn {
        text-align: right;
      }

      .taskViewButton {
        margin-right: 15px;
        margin-bottom: 15px;
      }

      .taskViewButton {
        max-width: 100px;
        margin-top: 10px;
        margin-right: 15px;
        margin-bottom: 15px;
      }

      .taskRemoveButton {
        max-width: 100px;
        margin-top: 10px;
        margin-right: 15px;
        margin-bottom: 15px;
        float: right;
      }

      .taskButton {
        margin-right: 15px;
        height: 36px !important;
      }

      .subHeading {
        margin-bottom: 20px;
      }

      h4 {
        display: inline;
      }

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 8px;
        margin-left: 10px;
      }
    }
    .checklistTemplateContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 20px;

      .margin5 {
        margin: 0px 5px;
      }

      .subHeading {
        @include font(4);
        color: $darkBlue !important ;
        margin-bottom: 30px;
      }

      .txtArea {
        width: 100%;
        height: 100px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .serviceChecklistInfo {
        margin-top: -10px;
        font-style: italic;
        font-size: small;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .viewRow {
        padding-top: 20px !important ;
      }
    }
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    .btnCancel {
      width: 120px !important;
      font-size: 12px !important;
    }
    .btnSaveChanges {
      width: 120px !important;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
}
