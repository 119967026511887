@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.profile {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;

    .spanHeading {
      @include font(2, 'medium');
    }

    .password {
      @include font(5, 'medium');
      margin-right: 15px;
    }

    .italicContent {
      @include font(2);
      font-style: italic;
      color: $grey !important;
    }

    .contentRow {
      border-radius: 6px;
      background-color: $white;
      margin-top: 30px;
    }
    .button {
      width: 102px;
      float: right;
    }
  }

  .error {
    @include font(2);
    color: $error !important;
    margin-top: 6px;
  }
  .saveButton {
    height: 36px !important;
    width: 102px !important;
    margin-left: 10px;
  }
  .formControlError {
    border: 1px solid $error !important;
  }
}
