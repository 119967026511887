@import '../../styles/variables';
@import '../../styles/global';

$rightColumnWidth: 370px;

.processFlow {
  width: 100%;

  .contentRow {
    margin-top: 20px;
  }

  .eventWrapper {
    width: 100%;
    padding: 20px 20px;
    border-radius: 4px;
    background-color: $white;
    box-sizing: border-box;
    position: relative;

    h4 {
      margin-left: 10px;
    }

    .viewAll {
      width: 200px !important;
      margin-left: 10px;
      padding: 12px 12px !important;
    }

    .evenlogButton {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  .commandPanel {
    padding-left: 15px;
  }
}

@media (max-width: 768px) {
  .commandPanel {
    padding-left: 0 !important;
    margin-top: 20px;
    text-align: center;
  }

  .eventWrapper {
    h4 {
      margin-left: 0px !important;
    }

    .viewAll {
      width: 90% !important;
      margin-left: 0px !important;
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .eventWrapper {
    h4 {
      margin-left: 0px !important;
    }
    .viewAll {
      margin-left: 0px !important;
    }
  }
}
