@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.modelVersionUnit {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 40px;

    .topRow {
      padding: 30px;
    }

    .topAndSideOnlyRow {
      padding: 30px 30px 0px 30px;
    }
    .sideRow {
      padding: 0px 30px;
      padding-bottom: 30px;
    }

    .margin5 {
      margin: 0px 5px;
    }
    .btnSaveChanges {
      width: 100px !important;
      height: 36px !important;
    }

    .addbutton {
      float: right;
    }

    .btnCancel {
      width: 100px;
      margin: 0px 5px;
      height: 36px !important;
    }
    .error {
      @include font(2);
      color: $error !important;
    }
    .ddlError > div {
      border: 1px solid $error;
    }

    .innerTable {
      padding: 1.25rem;
      vertical-align: top;
      border-top: none;
      font-size: 15px;
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 0px !important;

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: none;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .btnCol {
        text-align: right;
      }

      .viewButton {
        margin-top: 10px;
        float: right;
        max-width: 100px;
        margin-right: 20px;
      }
    }
    .newRow {
      border-top: 1px solid $border;
      border-bottom: 1px solid $border;
    }

    .borderBox {
      border: 1px solid $border;
      margin-left: 15px;
      margin: 30px;
    }

    .border30 {
      margin-left: 15px;
      margin: 30px;
      color: $danger;
    }

    .modelversionUnitAllocation {
      .row {
        margin-right: 0px !important;
        margin-left: 0px !important;
        border-bottom: solid 1px #d3dbdf;
        padding-top: 20px;
        padding-bottom: 15px;
        height: 90px;

        .reactSelectContainer {
          padding-bottom: 10px;

          div[class*='control'] {
            height: 48px;
          }

          span[class*='indicatorSeparator'] {
            background-color: $white;
          }

          svg {
            fill: $lightBlue;
          }
        }
      }
      .searchPadding {
        padding-right: 15px;
        padding-left: 15px;
      }
      .unitButtons {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
      }
    }

    .selectedlist {
      .selectedCols {
        height: 400px;
        overflow: auto;

        ul {
          li:hover {
            background-color: $greyBackground;
            cursor: pointer;
          }
        }
      }

      .table {
        td,
        th {
          // padding: 1.25rem;
          vertical-align: top;
          border-top: none;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }

        thead th {
          padding-bottom: 5px;
          border-bottom: none;
          color: $grey;
        }
        tbody td {
          vertical-align: middle;
          border-bottom: none;
          background-color: $greyBackground;
          cursor: pointer;
          &.selected {
            background-color: $darkBlue;
            color: $white;
            g {
              stroke: $white;
            }
          }
        }

        tbody tr {
          border-top: 8px solid $white;
          border-bottom: 8px solid $white;
        }
        thead th,
        tbody td {
          padding-left: 10px;
        }
        tr td:first-child {
          border-radius: 10px 0px 0px 10px;
          font-weight: 500;
          color: $textColor;
          width: 30px;
          &.selected {
            color: $white;
          }
        }
        tr td:last-child {
          border-radius: 0px 10px 10px 0px;
          color: $greyBackground;
        }

        tbody {
          tr {
            min-height: 50px;
          }
        }
      }

      ul > li {
        vertical-align: middle;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: $white;
        border-top: 6px solid $white;
        border-bottom: 1px solid $white;
        height: 53px;
        padding-left: 15px;
        display: flex;
        align-items: center;

        .selected {
          background-color: $greyBackground !important;
        }
      }
    }

    @media (max-width: 992px) {
      .addbutton {
        width: 100% !important;
      }
      .changeButton {
        float: right !important;
        width: 50px !important;
        font-size: 10px !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
  }
}
