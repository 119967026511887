@import '../../../styles/variables';
@import '../../../styles/global';

.wsaDropdown {
  button[class*='btn'] {
    width: 50px !important;
    background-color: transparent !important;
    color: $lightBlue !important;
    border: 1px solid $lightBlue !important;
    font-size: 25px !important;
    padding-bottom: 20px;
    height: 50px;
  }

  button[class*='dropdown-item'] {
    padding: 13px 1.5rem !important;
  }
  button::after {
    content: none;
  }

  a,
  a:hover {
    text-decoration: none;
  }

  button[class*='dropdown-item']:focus,
  button[class*='dropdown-item']:active {
    outline: none !important;
    box-shadow: none !important;
  }
}
