@import '../../../styles/variables';
@import '../../../styles/global';

.GoBackWrapper {
  .backLink {
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-bottom: 15px;
    display: block;
    border: none;
    background-color: transparent;
  }

  .backLink:hover {
    text-decoration: underline;
  }

  .backLink:active {
    text-decoration: underline;
    border: none;
    background-color: transparent;
  }

  .backLink:focus {
    outline: none !important;
  }
}
