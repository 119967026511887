@import '../../styles/variables';
@import '../../styles/global';

.scheduler {
  width: 100%;
  @include font(2);

  .btnSaveChanges {
    width: 102px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    height: 36px !important;
  }

  .btnCancel {
    width: 102px;
    margin: 0px 5px;
    height: 36px !important;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .schedulerHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .schedulerContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .breakhr {
        margin: 40px 0px;
      }
      .breakrow {
        margin-top: 15px !important;
      }
      .subheading {
        padding-top: 10px;
      }
    }
  }
}
