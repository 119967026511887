@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.modelVersion {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .topRow {
      padding: 30px;
    }

    .smallItalic {
      font-style: italic;
      @include font(3);
    }

    .margin5 {
      margin: 0px 5px;
    }
    .btnSaveChanges {
      width: 100px !important;
      height: 36px !important;
    }

    .addbutton {
      float: right;
    }

    .btnCancel {
      width: 100px;
      margin: 0px 5px;
      height: 36px !important;
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .ddlError > div {
      border: 1px solid $error;
    }

    .formControl {
      margin-bottom: 0px;
    }

    .formControlError {
      border: 1px solid $error !important;
    }

    .innerTable {
      padding: 1.25rem;
      vertical-align: top;
      border-top: none;
      font-size: 15px;
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 0px !important;

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: none;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .btnCol {
        text-align: right;
      }

      .changeButton {
        max-width: 100px;
        margin-top: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .removeButton {
        float: right;
        margin-top: 10px;
        max-width: 100px;
        margin-right: 20px;
      }

      .icon {
        cursor: pointer;
      }
    }

    .newRow {
      border-top: 1px solid $border;
      border-bottom: 1px solid $border;
    }

    @media (max-width: 768px) {
      .addbutton {
        width: 100% !important;
      }
      .changeButton {
        float: left !important;
        width: 50px !important;
        margin-right: 0px !important;
        font-size: 10px !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
      .removeButton {
        float: left !important;
        width: 50px !important;
        margin-right: 0px !important;
        font-size: 10px !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
  }
}
