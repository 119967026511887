@import '../../styles/variables';
@import '../../styles/global';

.profile {
  width: 100%;
  @include font(2);

  .fullWidth {
    @include fullWidth;
    background-color: $white;

    h1 {
      @include font(8, 'light');
      padding: 30px 0;
    }
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .profileHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .profileContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .btnCancel {
        margin: 0px 5px;
        height: 50px !important;
        padding-top: 12px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }
    }

    .profileWidgetsContent {
      .titleRows {
        padding-top: 25px;
        padding-left: 18px;
        padding-right: 15px;

        img {
          margin-right: 10px;
          cursor: pointer;
        }

        .link {
          cursor: pointer;
        }
        h6 {
          margin-top: 5px;
        }

        span {
          @include font(2, 'medium');
        }
      }

      .innerComponent {
        width: 100%;
        border-radius: 6px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
        background-color: $white;
        box-sizing: border-box;
        position: relative;
        padding: 30px !important;
      }

      ul.checkbox {
        margin: 0;
        padding: 0;
        margin-left: 0px;
        list-style: none;
      }

      ul.checkbox li input {
        margin-right: 0.25em;
      }

      ul.checkbox li {
        border: 1px transparent solid;
        display: inline-block;
        width: 23em;
      }

      ul.checkbox li label {
        margin-left: 0px;
      }

      /* The container */
      .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        @include font(2, 'medium');
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* Hide the browser's default checkbox */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        border: solid 1px $white;
        border-radius: 5px;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $white;
        border-radius: 5px;
        border: solid 1px $border;
      }

      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: $white;
        border-radius: 5px;
      }

      /* When the checkbox is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background-color: $lightBlue;
        border-radius: 5px;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .container .checkmark:after {
        left: 5px;
        top: 2px;
        width: 8px;
        height: 12px;
        border: solid $white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .selectall {
        font-weight: 600 !important;
        padding-bottom: 5px;
      }
      .wigetInfo {
        @include font(2);
        padding-bottom: 15px;
      }
    }

    .error {
      @include font(2);
      color: #db2269;
      display: relative;
    }

    .textArea {
      height: auto !important;
    }
  }
}

@media (max-width: 768px) {
  .btnCancel {
    width: 120px !important;
    font-size: 12px !important;
  }

  .btnSaveChanges {
    width: 120px !important;
    font-size: 12px !important;
    line-height: 15px !important;
  }
}
