@import '../../styles/variables';
@import '../../styles/global';

.pagination {
  width: 100%;

  .showing {
    display: flex;
    -ms-flex-item-align: left;
    margin-top: 20px;

    .info {
      color: $grey;
      font-size: 15px;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
    }

    @media (min-width: 576px) {
      float: left;
    }
  }

  .controls {
    display: flex;
    -ms-flex-item-align: right;
    // justify-content: space-between;
    margin-top: 20px;
    @media (min-width: 576px) {
      float: right;
    }
    @media (max-width: 575.99px) {
      justify-content: center;
    }

    .pageLinks {
      display: flex;
    }

    button {
      outline: none;
      padding: 6px 10px;
      border-radius: 6px;
      border: 1px solid $white;
      @include font(1);
      margin: 0 5px;
      position: relative;
      height: 50px;
      width: 50px;
      font-size: 15px;

      &.numbers {
        width: 50px;
        background-color: $white;
      }

      &.active {
        border: 1px solid $lightBlue;
        background-color: $lightBlue;
        color: $white !important;
      }

      &:first-child {
        margin-left: 0;
        &[disabled] {
          background-color: $background;
          border: 2px solid $white;
        }
        &:not(.active) {
          background-color: $white;
        }
      }

      &:last-child {
        margin-right: 0;
        &[disabled] {
          background-color: $background;
          border: 1px solid $white;
        }
        &:not(.active) {
          background-color: $white;
        }
      }

      &:hover:not(.active) {
        &:not([disabled]) {
          background-color: $background;
          border: 2px solid $lightBlue;
        }
      }

      &[disabled] {
        cursor: default;
        opacity: 0.5;
      }
    }
  }
}
