@import '../../styles/variables';
@import '../../styles/global';

.checklistTasksTemplates {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    button {
      // @include font(3, 'bold');
      color: $lightBlue;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .addbutton {
      padding-top: 9px !important;
      width: 180px !important;
    }

    .addbuttonCol {
      text-align: right;
    }

    .circle {
      display: inline-flex;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      border: solid 1px $darkBlue;
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-top: 8px;
      margin-left: 10px;
    }

    .titleRows {
      padding-top: 30px;
      padding-left: 18px;
      padding-right: 15px;

      img {
        margin-right: 10px;
      }

      h4 {
        padding-top: 5px;
      }
      .link {
        cursor: pointer;
      }
    }

    .innerComponent {
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
      background-color: $white;
      box-sizing: border-box;
      position: relative;

      .component {
        margin-top: 20px;
        margin-bottom: 20px;

        .topRow {
          padding: 30px;
        }
      }

      .innerTable {
        padding: 1.25rem;
        vertical-align: top;
        border-top: none;
        font-size: 15px;
        width: 100%;
        padding-bottom: 0px;
        margin-bottom: 0px !important;

        thead th {
          padding-bottom: 5px;
          background-color: $border;
          border: none !important;
          color: $textColor;
          height: 40px;
          vertical-align: middle !important;
          padding-top: 5px !important;
        }

        tbody tr {
          border: none;
        }

        tr td:first-child {
          font-weight: normal;
        }

        tbody tr td {
          border-top: 1px solid $border;
        }

        .btnCol {
          text-align: right;
        }

        .viewButton {
          float: right;
          width: 102px;
          margin-right: 20px;
        }
      }
    }

    @media (max-width: 768px) {
    }
  }
}
