@import '../../styles/variables';
@import '../../styles/global';

.borderRed {
  border-left: solid 6px $danger !important;
}

.borderYellow {
  border-left: solid 6px $alert !important;
}

.deviceTile {
  width: 100%;
  height: 100%;
  display: flex;
  text-decoration: none;
  color: $textColor;
  justify-content: space-around;
  text-align: center;
  cursor: pointer;
  position: relative;

  .contentWrapper {
    width: 90%;
    text-align: left;
    margin-top: 24px;

    h2 {
      margin-bottom: 0.9375rem;
    }

    .tileDeviceId {
      margin-bottom: 16px;
    }

    .rightIcons {
      float: right;
      margin-top: -12px;
      width: auto !important;
    }

    .iconsize {
      width: 15px;
      fill: #085488 !important;
    }

    .taggedout {
      width: 20px;
      min-height: 20px;
      float: left;
    }

    .scheduleIcon {
      width: 20px;
      min-height: 20px;
      margin-right: 5px;
      float: left;
    }

    .deviceInfo {
      h3 {
        margin-bottom: 2px !important;
      }
      h6 {
        font-size: 14px !important;
        font-weight: 400 !important;
        margin-bottom: 12px;
      }

      p {
        margin-bottom: 9px !important;
      }
    }

    .content {
      @include font(2);
      margin-bottom: 0.625rem;
      line-height: normal !important;

      img {
        margin-right: 4px;
        top: 2px;
      }

      span {
        font-weight: normal;
      }
    }

    .alarmOnly {
      position: absolute !important;
      bottom: 0px;
      width: 90%;
      height: 40px;

      img {
        margin-right: 5px;
      }
    }

    .alarmWarning {
      position: absolute !important;
      bottom: 50px;
      width: 90%;
      height: 40px;

      img {
        margin-right: 5px;
      }
    }

    .warning {
      position: absolute !important;
      bottom: 0px;
      width: 90%;
      height: 40px;

      img {
        margin-right: 5px;
      }
    }

    .tagoutWrapper {
      max-width: 250px;
    }
  }
}

.deviceTile:hover {
  text-decoration: none !important;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .contentWrapper {
    h2 {
      font-size: 24px !important;
    }
  }
}
