@import '../../styles/variables';
@import '../../styles/global';

.searchContainer {
  .clearLink {
    @include font(2, 'medium');
    color: $darkBlue !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 0.25px;
    cursor: pointer;
  }
  .paddingtop12 {
    padding-top: 12px;
  }

  .searchBarIcon {
    display: flex;
    align-items: center;
    padding-top: 0;
  }

  .searchtextboxes {
    display: flex;

    .formControl {
      font-size: 15px !important;
      padding: 17px;
      flex: 2;
    }

    .formControl::placeholder {
      color: #919191 !important;
    }
    .searchType {
      flex: 1;
      padding-right: 10px;
    }
  }
}
