@import '../../styles/variables';
@import '../../styles/global';

.bgColorBlue {
  background-color: $statisticsBlue;
}

.bgColorGreen {
  background-color: $statisticsGreen;
}

.bgColorViking {
  background-color: $statisticsViking;
}

.statTile {
  width: 100%;
  min-height: 160px;
  display: flex;
  text-decoration: none;
  color: $textColor;
  justify-content: space-around;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  margin-bottom: 10px;

  .contentWrapper {
    width: 100%;
    text-align: left;
    padding: 30px;

    .imgIcon {
      float: right;
      margin-left: 10px;
    }
    .statText {
      @include font(3, 'medium');
      color: $white !important;
    }
    .statValue {
      @include font(5, 'medium');
      color: $white !important;
      position: absolute;
      bottom: 30px;
    }
    .btnSaveChanges {
      min-width: 75px;
      width: 100%;
    }
    .btnCancel {
      min-width: 75px;
      width: 100%;
    }
    .margin5 {
      margin-bottom: 5px;
    }
  }
}

.deviceTile:hover {
  text-decoration: none !important;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .contentWrapper {
    h2 {
      font-size: 24px !important;
    }
  }
}
