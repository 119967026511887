@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.wrapper {
  margin: auto;
  //align-self: center;
  //position: relative;
  width: 90%;

  .login {
    margin: auto;
    align-self: center;
    position: relative;
    width: 80%;

    h1 {
      margin-bottom: 40px !important;
    }

    .loginButton {
      margin-top: 12px;
    }

    span {
      @include font(2, 'medium');
      color: $darkBlue !important;
      float: right;
      margin-top: 25px;
    }
    span:hover {
      text-decoration: underline;
    }
  }

  .error {
    @include font(2);
    color: $error !important;
    margin-top: 6px;
  }

  .formControlError {
    border: 1px solid $error !important;
  }

  .alertSuccess {
    @include font(2, 'medium');
    background-color: $alertGreen !important;
    border: none;
    display: flex;
    flex-direction: row;
    font-size: 14px !important;

    div {
      padding-left: 10px;
      padding-top: 5px;
    }
  }

  .alertError {
    display: flex;
    flex-direction: row;
    font-size: 14px !important;

    div {
      padding-left: 10px;
      padding-top: 5px;
    }
  }

  .logo {
    position: absolute;
    top: 3%;
    left: 5%;
    width: 150px;
  }

  .contactSection {
    position: absolute;
    top: 3%;
    right: 5%;

    span {
      @include font(2);
      color: $darkBlue !important;
      margin-right: 20px;
    }
  }
}

@media (max-width: 768px) {
  .contactSection {
    bottom: 3%;
    left: 5%;
    top: auto !important;
    right: auto !important;
  }

  .login span {
    float: left !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .login span {
    float: left !important;
  }
}
