.st3 {
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.st3,
.st4,
.st9 {
  fill: none;
  stroke: #000;
  stroke-width: 0.5;
  stroke-linejoin: round;
}
.st9 {
  stroke-miterlimit: 10;
  stroke-dasharray: 4, 3;
}
.st10 {
  font-family: ArialNarrow;
}
.st11 {
  font-size: 5.4189px;
}
.st21 {
  font-size: 4px;
}
.st12,
.st16 {
  fill: #fff;
  stroke: #000;
  stroke-width: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #e80000;
}

.line-default {
  stroke: #000000;
  stroke-width: 1;
}

.hash-line-default {
  stroke: #000000;
  stroke-width: 1;
}

.sen-red {
  fill: #ff2f2f;
}

.sen-amber {
  fill: #ffc000;
}

.sen-green_dark {
  fill: #00a400;
}

.sen-brown_dark {
  fill: #9e7500;
}

.line-brown_dark {
  stroke: #9e7500;
  stroke-width: 4;
}

.sen-brown_light {
  fill: #ce9d6c;
}

.line-brown_light {
  stroke: #ce9d6c;
  stroke-width: 4;
}

.sen-blue_dark {
  fill: #0b20b9;
}

.line-blue_dark {
  stroke: #0b20b9;
  stroke-width: 4;
}

.sen-blue_light {
  fill: #9bc2e6;
}

.line-blue_light {
  stroke: #9bc2e6;
  stroke-width: 4;
}

.sen-blue_lightest {
  fill: #0af9f9;
}

.line-blue_lightest {
  stroke: #0af9f9;
  stroke-width: 4;
}

.sen-violet {
  fill: #6600ff;
}

.sen-yellow {
  fill: #ffff3c;
}

.line-yellow {
  stroke: #ffff3c;
  stroke-width: 4;
}

.sen-orange {
  fill: #eadf4b;
}

.line-orange {
  stroke: #eadf4b;
  stroke-width: 4;
}

.sen-green_light {
  fill: #dafe68;
}

.st20 {
  fill: none;
  stroke: #02b04e;
  stroke-width: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 4, 3;
}

rect {
  stroke: black;
}

.inputWaterGradient {
  stop-opacity: 1;
  stop-color: #963;
}
.inputWater {
  stop-opacity: 1;
  stop-color: #963;
}
.empty {
  stop-color: #963;
}
.clear {
  stop-opacity: 0;
}
.clear-white {
  stop-opacity: 1;
  stop-color: #ffffff;
}

.outputWaterGradient {
  stop-opacity: 1;
  stop-color: #7030a0;
}

.outputWater {
  stop-opacity: 1;
  stop-color: #7030a0;
}

#dv-schematics {
  width: 100%;
  height: 100%;
}

.scaling-svg-container {
  height: 600px;
}

.clsSensorTooltip {
  opacity: 0.8;
}

.polyLineDotted {
  fill: none;
  stroke: #000000;
  stroke-width: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 11.2726, 2.9665, 4.153, 2.9665, 4.153, 2.9665;
}
