.ver1-sen-red {
  fill: #dc3545 !important;
}

.ver1-sen-amber {
  fill: #ffbf00 !important;
}

.ver1-sen-green {
  fill: #20c997 !important;
}
.ver1-sen-white {
  fill: #ffff !important;
}
.ver1-line-default {
  stroke: #000000 !important;
  stroke-width: 0.17 !important;
}

.hash-line-default {
  stroke: #000000 !important;
}

.ver1-sen-red {
  fill: #dc3545 !important;
}

.ver1-sen-amber {
  fill: #ffc000 !important;
}

.ver1-sen-green_dark {
  fill: #00a400 !important;
}

.ver1-sen-brown_dark {
  fill: #9e7500 !important;
}

.ver1-line-brown_dark {
  stroke: #9e7500 !important;
  /* stroke-width: 1 !important; */
}

.legend-brown_dark {
  stroke: #9e7500 !important;
}

.ver1-sen-brown_light {
  fill: #ce9d6c !important;
}

.ver1-line-brown_light {
  stroke: #ce9d6c !important;
  /* stroke-width: 1 !important; */
}

.legend-brown_light {
  stroke: #ce9d6c !important;
}

.ver1-sen-blue_dark {
  fill: #007bff !important;
}

.ver1-line-blue_dark {
  stroke: #007bff !important;
  stroke-width: 0.6 !important;
}

.legend-blue_dark {
  stroke: #007bff !important;
  text-align: left !important;
}

.ver1-sen-blue_light {
  fill: #17a2b8 !important;
}

.ver1-line-blue_light {
  stroke: #17a2b8 !important;
  /* stroke-width: 1 !important;  */
}

.legend-blue_light {
  stroke: #17a2b8 !important;
}

.ver1-sen-blue_lightest {
  fill: #17a2b8 !important;
}

.ver1-line-blue_lightest {
  stroke: #17a2b8 !important;
  /* stroke-width: 1 !important; */
}

.ver1-sen-violet {
  fill: #a088c4 !important;
}

.ver1-sen-yellow {
  fill: #ffc107 !important;
}

.ver1-line-yellow {
  stroke: #ffc107 !important;
  stroke-width: 1 !important;
}

.legend-yellow {
  stroke: #ffc107 !important;
}

.ver1-sen-orange {
  fill: #fd7e14 !important;
}

.legend-orange {
  stroke: #ffc107 !important;
}

.ver1-line-orange {
  stroke: #fd7e14 !important;
  stroke-width: 1 !important;
  /* stroke-width:0.7 !important;  */
}

.ver1-line-purple {
  stroke: #824cff !important;
  stroke-width: 0.5 !important;
}

.legend-purple {
  stroke: #824cff !important;
}

.ver1-sen-green_light {
  fill: #dafe68 !important;
}

.show-sensor {
  font-weight: bold;
  display: block;
}

.hide-sensor {
  display: none;
}

.sensor-alignleft {
  text-align: left !important;
}

.bms-body-empty {
  stroke: #c8c8c8 !important;
}
.bms-cell-empty {
  fill: #c8c8c8 !important;
}

.bms-body-100 {
  stroke: #00a900 !important;
}
.bms-body-75 {
  stroke: #ffcc00 !important;
}
.bms-body-50 {
  stroke: #aa4400 !important;
}
.bms-body-25 {
  stroke: #d40000 !important;
}

.bms-100 {
  fill: #00a900 !important;
}
.bms-75 {
  fill: #ffcc00 !important;
}
.bms-50 {
  fill: #aa4400 !important;
}
.bms-25 {
  fill: #d40000 !important;
}

.bms-empty {
  display: none !important;
}

.ver1-sen-none {
  fill: none !important;
}
