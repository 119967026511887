@import '../../styles/variables';
@import '../../styles/global';

.organisation {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .organisationHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .organisationContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .rowContainer {
        padding-left: 0px;
      }

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .btnSaveChanges {
        width: 230px;
      }

      .btnCancel {
        width: 230px;
        margin: 0px 5px;
        height: 50px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .wsaDropdown {
        width: 100%;
        padding-bottom: 10px;

        div[class*='control'] {
          height: 48px;
        }

        div[class*='menu-list'] {
          div[class*='option'] {
            border-bottom: solid 1px $border;
          }
        }

        span[class*='indicatorSeparator'] {
          background-color: $white;
        }

        div[class*='indicatorContainer'] svg {
          fill: $darkBlue;
          path {
            d: path(
              'M4.516 7.248c0.236-0.246 0.043-0.481 1.276 0l3.908 3.747 3.908-3.347c0.533-0.481 0.141-0.446 1.574 0 0.136 0.145 0.108 0.197 0 1.215-0.106 0.118-4.295 3.502-4.195 3.502-0.117 0.123-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.135c0 0-4.287-4.084-4.695-4.502s-0.136-1.17 0-1.615z'
            ) !important;
          }
        }
      }

      .uploadicon {
        color: #d3dbdf;
      }

      .uploadImage {
        height: 40px;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
      }

      .btnUpload {
        height: 36px !important;
      }
      .error {
        color: #e24545;
      }
      .textDrag {
        @include font(5, 'normal');
        margin: 20px 0px;
        color: $grey !important;
      }
      .or {
        @include font(2, 'normal');
        margin-bottom: 20px;
        color: $grey !important;
      }
      .texFile {
        @include font(3, 'normal');
        margin: 20px 0px;
        text-align: center;
        color: $grey !important;
      }
    }

    .organisationUsers {
      .row {
        border-bottom: solid 1px #d3dbdf;
        padding-top: 20px;
        padding-bottom: 15px;
        height: 90px;

        .reactSelectContainer {
          padding-bottom: 10px;

          div[class*='control'] {
            height: 48px;
          }

          span[class*='indicatorSeparator'] {
            background-color: $white;
          }

          svg {
            fill: $lightBlue;
          }
        }
      }
    }

    .selectedUsersAndUnits {
      .selectedCols {
        height: 400px;
        overflow: auto;

        ul {
          li:hover {
            background-color: $greyBackground;
            cursor: pointer;
          }
        }
      }

      .table {
        td,
        th {
          // padding: 1.25rem;
          vertical-align: top;
          border-top: none;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }

        thead th {
          padding-bottom: 5px;
          border-bottom: none;
          color: $grey;
        }
        tbody td {
          vertical-align: middle;
          border-bottom: none;
          background-color: $greyBackground;
          cursor: pointer;
          &.selected {
            background-color: $darkBlue;
            color: $white;
            g {
              stroke: $white;
            }
          }
        }

        tbody tr {
          border-top: 8px solid $white;
          border-bottom: 8px solid $white;
        }
        thead th,
        tbody td {
          padding-left: 10px;
        }
        tr td:first-child {
          border-radius: 10px 0px 0px 10px;
          font-weight: 500;
          color: $textColor;
          width: 30px;
          &.selected {
            color: $white;
          }
        }
        tr td:last-child {
          border-radius: 0px 10px 10px 0px;
          color: $greyBackground;
        }

        tbody {
          tr {
            min-height: 50px;
          }
        }
      }

      ul > li {
        vertical-align: middle;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: $white;
        border-top: 6px solid $white;
        border-bottom: 1px solid $white;
        height: 53px;
        padding-left: 15px;
        display: flex;
        align-items: center;

        .selected {
          background-color: $greyBackground !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .users {
    .row {
      height: 150px !important;
    }
  }

  .organisationContent {
    .btnCancel {
      width: 120px !important;
      font-size: 12px !important;
    }
    .btnSaveChanges {
      width: 120px !important;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
}
