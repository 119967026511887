@import '../../styles/variables';
@import '../../styles/global';

.modelConfiguration {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .modelHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .btnUpdateShadow {
      width: unset;
      height: 50px;
    }

    .alignRight {
      text-align: right;
    }

    .modelContent {
      width: 100%;

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 8px;
        margin-left: 10px;
      }

      .titleRows {
        padding-top: 30px;
        padding-left: 18px;
        padding-right: 15px;

        .innerComponent {
          width: 100%;
          border-radius: 6px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
          background-color: $white;
          box-sizing: border-box;
          position: relative;
        }

        img {
          margin-right: 10px;
        }

        h4 {
          padding-top: 5px;
        }
        .link {
          cursor: pointer;
        }
      }
    }
    @media (max-width: 768px) {
      .btnUpdateShadow {
        width: 100% !important;
      }
    }
  }
}
