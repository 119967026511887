@import '../../../styles/variables';
@import '../../../styles/global';

p {
  margin-bottom: 1rem !important;
}

.activityHistory {
  width: 100%;
  border-bottom: solid 1px $border;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;

  .activityHistoryContainer {
    .activityHistoryTitle {
      @include font(2, 'medium');
      color: $textColor !important;
    }

    .activityDate {
      margin-top: -15px;
      @include font(2);
      color: $grey !important;
      font-weight: normal;
    }

    .activityHistoryContents {
      background-color: $white;
      @include font(2);
      color: $textColor;
    }

    .avatar {
      height: 30px;
      width: 30px;
      background-color: $avatarBackground;
      border-radius: 15px;
    }

    .avatarDiv {
      padding-left: 3px !important;
      padding-right: 0px !important;
      max-width: 35px !important;
    }
  }
}
