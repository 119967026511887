@import '../../styles/variables';
@import '../../styles/global';

.globalSettings {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .globalSettingsHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .btnRow {
      margin-top: 30px;
    }
    .btnSaveChanges {
      width: 230px;
      margin: 0px 5px 0px 0px !important;
    }

    .btnCancel {
      width: 230px;
      height: 50px !important;
    }

    .colChangeButton {
      text-align: right;
    }

    .globalSettingsContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 20px;

      .margin5 {
        margin: 0px 5px;
      }

      .subHeading {
        @include font(4);
        color: $darkBlue !important ;
        margin-bottom: 30px;
      }

      .txtArea {
        width: 100%;
        height: 100px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .viewRow {
        padding-top: 20px !important ;
      }
    }
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    .btnCancel {
      width: 120px !important;
      font-size: 12px !important;
    }
    .btnSaveChanges {
      width: 120px !important;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
}
