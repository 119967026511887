@import '../../styles/variables';
@import '../../styles/global';

.unitServiceDetail {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .unitServiceDetailHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .btnSaveChanges {
      width: 230px;
      margin: 0px 5px 5px 0px !important;
    }

    .btnCancel {
      width: 230px;
      height: 50px !important;
      margin: 0px 5px 5px 0px !important;
    }

    .colActionButton {
      float: right;
      margin-right: 0px;
      max-width: 135px;
    }

    .partsServiceContainer {
      margin-top: 10px;

      .tableContainer {
        width: calc(100% + 80px);
        margin-left: -40px;
      }

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: none;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .noborder {
        border-top: 0px !important;
      }

      .buttonContainer {
        display: flex;
        justify-content: flex-end;
      }

      .changeButton {
        float: right;
        width: 100px;
        margin-right: 20px;
      }

      .removeButton {
        float: right;
        width: 100px;
        margin-right: 20px;
      }

      .colAddButton {
        text-align: right;
      }

      .ddlTaskDetail {
        max-width: 350px !important;
      }
      .partsServiceRow {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .activeTaskRow {
        border-left: solid 6px $danger !important;
      }

      .emptyRow {
        text-align: center;
        border-left: 0px !important;
      }

      .partsServiceRowButton {
        margin-right: 15px;
        float: right;
        margin-bottom: 5px;
      }

      .taskButton {
        margin-right: 15px;
        height: 36px !important;
      }

      .subHeading {
        margin-bottom: 20px;
      }

      h4 {
        display: inline;
      }

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 8px;
        margin-left: 10px;
      }
      .ddlTaskStatusSearch {
        max-width: 180px;
      }

      .sortIcon {
        height: 12.2px;
        width: 10px;
        margin-left: 8px;
      }
      .sortHeading {
        cursor: pointer;
      }

      .overDue {
        padding-left: 5px;
        font-style: italic;
        color: $grey;
      }
    }
    .unitServiceDetailContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 20px;

      .warningMsg {
        font-style: italic;
        @include font(1);
        margin-top: -6px;
      }

      .statusMessage {
        color: $error !important;
      }
      .margin5 {
        margin: 0px 5px;
      }

      .subHeading {
        @include font(4);
        color: $darkBlue !important ;
        margin-bottom: 30px;
      }

      .txtArea {
        width: 100%;
        height: 100px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .viewRow {
        padding-top: 20px !important ;
      }
      .dueDateTime {
        max-width: 200px;
      }

      .taskCompleted {
        @include font(3, 'medium');
      }
    }
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    .btnCancel {
      width: 120px !important;
      font-size: 12px !important;
    }
    .btnSaveChanges {
      width: 120px !important;
      font-size: 12px !important;
      line-height: 15px !important;
      margin-bottom: 5px;
    }
  }
  .unitServiceDetailContent {
    .dueDateTime {
      max-width: unset;
    }
  }
}
