@import '../../styles/variables';
@import '../../styles/global';

.borderRed {
  border-left: solid 6px $danger !important;
}

p {
  margin-bottom: 1rem !important;
}
.eventActionRHS {
  padding-top: 15px;
  width: 100%;

  .eventActionRHSContainer {
    .eventActionRHSButton {
      margin-bottom: 30px;
      margin-top: 30px;
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .eventActionRHSHeading {
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .eventActionRHSContents {
      @include font(2);
      color: $textColor;
    }
    .link:hover {
      text-decoration: none;
    }
    .eventActionRHSSubHeading {
      @include font(2, 'medium');
      color: $textColor;
    }
    .removeLink {
      cursor: pointer;
    }

    .eventActionRHSSubHeadingContent {
      margin-top: -20px;
    }
    .error {
      @include font(2);
      margin-top: -10px;
      color: $error !important;
    }
  }
}
