@import '../../styles/variables';
@import '../../styles/global';

.SensorTableWrapper {
  flex: 0 0 60%;
  padding: 20px 30px;
  border-radius: 4px;
  background-color: $white;
  box-sizing: border-box;
  position: relative;

  span {
    @include font(2, 'medium');
    color: $darkBlue !important;
  }

  .isCCPorOCP {
    color: $alert;
  }

  .sensorContent {
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: solid 1px $greyBackground;
    @include font(2);
  }
}

@media (max-width: 768px) {
  .SensorTableWrapper {
    padding: 20px 0px !important;

    .sensorName {
      font-weight: 500 !important;
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .SensorTableWrapper {
    padding: 20px 0px !important;
  }
}
