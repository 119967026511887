@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.devices {
  width: 100%;

  .fullWidth {
    span {
      @include font(2);
      color: $grey !important;
    }
  }
}
