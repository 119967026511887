@import '../../styles/variables';
@import '../../styles/global';

.user {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .userHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .userContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }
      .btnAdd {
        width: 100%;
      }

      .btnCancel {
        margin: 0px 5px;
        height: 50px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
    }
  }
}

.userNumUnits {
  font-style: italic;
}

.userProfileUnitsContainer {
  margin-top: 20px;
  .outerLeftCol {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
    border-left: 1px solid $border;
    margin-bottom: 15px;
  }

  .outerRightCol {
    border: 1px solid $border;
    margin-bottom: 15px;
  }

  .subHeading {
    @include font(2, 'medium');
    font-size: 20px !important;
    color: $darkBlue !important;
  }

  .subHeading2 {
    height: 18px !important;
    font-size: 15px !important;
    margin-bottom: 15px;
  }

  .userProfiles {
    .row {
      border-bottom: solid 1px #d3dbdf;
      padding-top: 20px;
      padding-bottom: 15px;
      height: 90px;

      .reactSelectContainer {
        padding-bottom: 10px;

        div[class*='control'] {
          height: 48px;
        }

        span[class*='indicatorSeparator'] {
          background-color: $white;
        }

        svg {
          fill: $lightBlue;
        }
      }
    }
  }

  .selectedProfilesNUnits {
    .selectedCols {
      height: 400px;
      overflow: auto;

      ul {
        li:hover {
          background-color: $greyBackground;
          cursor: pointer;
        }
      }
    }

    .table {
      td,
      th {
        // padding: 1.25rem;
        vertical-align: top;
        border-top: none;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

      thead th {
        padding-bottom: 5px;
        border-bottom: none;
        color: $grey;
      }
      tbody td {
        vertical-align: middle;
        border-bottom: none;
        background-color: $greyBackground;
        cursor: pointer;
        &.selected {
          background-color: $darkBlue;
          color: $white;
          g {
            stroke: $white;
          }
        }
      }

      tbody tr {
        border: none;
        border-top: 8px solid $white;
        border-bottom: 8px solid $white;
      }
      thead th,
      tbody td {
        padding-left: 10px;
      }
      tr td:first-child {
        border-radius: 10px 0px 0px 10px;
        font-weight: 500;
        color: $textColor;
        width: 30px;
        &.selected {
          color: $white;
        }
      }
      tr td:last-child {
        border-radius: 0px 10px 10px 0px;
        color: $greyBackground;
      }

      tbody {
        tr {
          min-height: 50px;
        }
      }
    }

    ul > li {
      vertical-align: middle;
      border-top-right-radius: 9px;
      border-top-left-radius: 9px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: $white;
      border-top: 6px solid $white;
      border-bottom: 1px solid $white;
      height: 53px;
      padding-left: 15px;
      display: flex;
      align-items: center;

      .selected {
        background-color: $greyBackground !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .userProfiles {
    .row {
      height: 150px !important;
    }
  }

  .userProfileUnitsContainer {
    .outerLeftCol {
      border-right: 1px solid $border;
    }
  }

  .userContent {
    .btnCancel {
      width: 120px;
      font-size: 12px !important;
    }
    .btnSaveChanges {
      width: 120px;
      font-size: 12px !important;
    }
  }
}
